import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import PrimaryHeader from "../layouts/header/PrimaryHeader";
import { useEffect, useState } from "react";
import FooterMain from "../layouts/footer/FooterMain";
import SupportMain from "../layouts/main/Support";
import { DEFAULT_LANGUAGE } from "../utils/global_const";
const Container = styled.div`
  background-color: var(--background-color);
  color: var(--text-color);
`;
const SEOConfig = {
  en: {
    lang: "en",
    title: "Support | SnusCo",
    description:
      "Get help with your orders, products, or account. Our support team is here to assist you with any inquiries.",
    og: {
      title: "Support | SnusCo",
      description:
        "Need assistance? Our SnusCo support team is ready to help with your questions and concerns.",
    },
  },
  de: {
    lang: "de",
    title: "Support | SnusCo",
    description:
      "Holen Sie sich Hilfe bei Ihren Bestellungen, Produkten oder Ihrem Konto. Unser Support-Team steht Ihnen bei Fragen zur Verfügung.",
    og: {
      title: "Support | SnusCo",
      description:
        "Benötigen Sie Hilfe? Unser SnusCo-Support-Team steht bereit, um Ihnen bei Fragen und Bedenken zu helfen.",
    },
  },
  it: {
    lang: "it",
    title: "Supporto | SnusCo",
    description:
      "Ottieni assistenza per i tuoi ordini, prodotti o account. Il nostro team di supporto è qui per aiutarti con qualsiasi richiesta.",
    og: {
      title: "Supporto | SnusCo",
      description:
        "Hai bisogno di aiuto? Il nostro team di supporto SnusCo è pronto ad assisterti con le tue domande e preoccupazioni.",
    },
  },
  fr: {
    lang: "fr",
    title: "Support | SnusCo",
    description:
      "Obtenez de l'aide pour vos commandes, produits ou compte. Notre équipe de support est là pour vous aider avec vos demandes.",
    og: {
      title: "Support | SnusCo",
      description:
        "Besoin d'assistance? Notre équipe de support SnusCo est prête à vous aider avec vos questions et préoccupations.",
    },
  },
  hu: {
    lang: "hu",
    title: "Támogatás | SnusCo",
    description:
      "Segítségre van szüksége a rendeléseivel, termékeivel vagy fiókjával kapcsolatban? Támogató csapatunk itt van, hogy segítsen bármilyen kérdésében.",
    og: {
      title: "Támogatás | SnusCo",
      description:
        "Segítségre van szüksége? SnusCo támogató csapatunk készen áll, hogy segítsen kérdéseivel és aggodalmaival kapcsolatban.",
    },
  },
  tr: {
    lang: "tr",
    title: "Destek | SnusCo",
    description:
      "Siparişleriniz, ürünleriniz veya hesabınızla ilgili yardım alın. Destek ekibimiz sorularınızla size yardımcı olmak için burada.",
    og: {
      title: "Destek | SnusCo",
      description:
        "Yardıma mı ihtiyacınız var? SnusCo destek ekibimiz, sorularınız ve endişelerinizle ilgili size yardımcı olmaya hazır.",
    },
  },
  sr: {
    lang: "sr",
    title: "Podrška | SnusCo",
    description:
      "Dobijte pomoć za vaše narudžbine, proizvode ili nalog. Naš tim za podršku je ovde da vam pomogne sa bilo kojim upitom.",
    og: {
      title: "Podrška | SnusCo",
      description:
        "Treba vam pomoć? Naš SnusCo tim za podršku je spreman da vam pomogne sa vašim pitanjima i brigama.",
    },
  },
};
const Support = () => {
  const { t, i18n } = useTranslation();
  const [seo, setSeo] = useState(SEOConfig[DEFAULT_LANGUAGE]);

  useEffect(() => {
    setSeo(SEOConfig[i18n.language] || SEOConfig[DEFAULT_LANGUAGE]);
  }, [i18n.language]);

  return (
    <Container>
      <Helmet>
        <html lang={seo.lang} />
        <title>{seo.title}</title>
        <link
          rel="alternate"
          href="http://www.snusco.com/en/support"
          hreflang={seo.lang}
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/de/support"
          hreflang="de"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/it/support"
          hreflang="it"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/fr/support"
          hreflang="fr"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/hu/support"
          hreflang="hu"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/tr/support"
          hreflang="tr"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/sr/support"
          hreflang="sr-latn"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/support"
          hreflang="x-default"
        />

        <meta name="description" content={seo.description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={seo.og.title} />
        <meta property="og:description" content={seo.og.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://www.snusco.com/support" />
        <meta
          property="og:image"
          content="http://www.snusco.com/assets/og-image.jpg"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="SnusCo" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <PrimaryHeader />
      <SupportMain />
      <FooterMain />
    </Container>
  );
};

export default Support;
