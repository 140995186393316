import styled from "styled-components";
import bannerImage from "../../assets/images/home-banner.png";
import bannerMobileImage from "../../assets/images/banner-mobile/banner-mobile_3x.webp";
import { useNavigation } from "../../utils/navigation";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Wrapper = styled.div`
  margin: var(--spacing-xxl) 0;
  width: 100%;
  @media (min-width: 1025px) {
    width: var(--max-width-container);
  }
  @media (min-width: 765px) {
    background-image: url(${bannerImage});
    height: 270px;
    border: 1px solid rgb(171, 201, 223);
  }
  height: 90vh;
  background-image: url(${bannerMobileImage});
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
`;
const TextContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 40px;
  height: 90%;

  flex-direction: column;
  text-align: center;

  @media (min-width: 768px) and (max-width: 1040px) {
    height: 90%;
    align-items: center;
  }
  @media (min-width: 765px) {
    flex-direction: row;
    height: 100%;
    text-align: left;
    width: 90%;
  }
`;
const Title = styled.h2`
  font-size: var(--font-size-xlarge);
  font-family: "Larken" !important;
  font-weight: 400;
  margin: var(--spacing-lg) 0 var(--spacing-md) 0;
  text-transform: uppercase;
  max-width: 100%;
  @media (min-width: 765px) {
    font-size: var(--font-size-xxlarge);
  }
`;
const Text = styled.p`
  color: var(--text-color);
  font-size: var(--font-size-base);

  @media (min-width: 765px) {
    font-size: var(--font-size-base);
    max-width: 65%;
  }
`;
const Subtitle = styled.h4`
  font-family: "Satoshi";
  font-weight: 700;
  font-size: var(--font-size-base);
  opacity: 0.5;
`;
const Button = styled.button`
  white-space: nowrap;
`;
const BannerHorizont = () => {
  const { t } = useTranslation();
  const { goToShop } = useNavigation();
  const navigate = useNavigate();
  return (
    <Container>
      <Wrapper>
        <TextContainer>
          <div>
            <Subtitle>{t("HOME.BANNER_SUBTITLE")}</Subtitle>
            <Title>{t("HOME.BANNER_TITLE")}</Title>
            <Text>{t("HOME.BANNER_TEXT")}</Text>
          </div>
          <Button
            className="button-transparent"
            onClick={() => navigate(goToShop())}
          >
            {t("BUTTONS.SHOP_NOW")}
          </Button>
        </TextContainer>
      </Wrapper>
    </Container>
  );
};

export default BannerHorizont;
