import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import PrimaryHeader from "../layouts/header/PrimaryHeader";
import { useEffect, useState } from "react";
import FooterMain from "../layouts/footer/FooterMain";
import SearchMain from "../layouts/main/SearchMain";
import { DEFAULT_LANGUAGE } from "../utils/global_const";
const Container = styled.div`
  background-color: var(--background-color);
  color: var(--text-color);
`;
const SEOConfig = {
  en: {
    lang: "en",
    title: "Search Products | SnusCo",
    description:
      "Find the best nicotine pouches with our search tool. Browse through a wide range of flavors and strengths to match your preferences.",
    og: {
      title: "Search Products | SnusCo",
      description:
        "Use SnusCo's search feature to discover the perfect nicotine pouches for you. Filter by flavor, strength, and more.",
    },
  },
  de: {
    lang: "de",
    title: "Produkte durchsuchen | SnusCo",
    description:
      "Finden Sie die besten Nikotinbeutel mit unserem Suchtool. Durchsuchen Sie eine große Auswahl an Geschmacksrichtungen und Stärken, die Ihren Vorlieben entsprechen.",
    og: {
      title: "Produkte durchsuchen | SnusCo",
      description:
        "Nutzen Sie die Suchfunktion von SnusCo, um die perfekten Nikotinbeutel für sich zu entdecken. Filtern Sie nach Geschmack, Stärke und mehr.",
    },
  },
  it: {
    lang: "it",
    title: "Cerca Prodotti | SnusCo",
    description:
      "Trova le migliori pouches di nicotina con il nostro strumento di ricerca. Sfoglia una vasta gamma di sapori e intensità che corrispondono alle tue preferenze.",
    og: {
      title: "Cerca Prodotti | SnusCo",
      description:
        "Utilizza la funzione di ricerca di SnusCo per scoprire le pouches di nicotina perfette per te. Filtra per sapore, intensità e altro ancora.",
    },
  },
  fr: {
    lang: "fr",
    title: "Rechercher des Produits | SnusCo",
    description:
      "Trouvez les meilleurs sachets de nicotine avec notre outil de recherche. Parcourez une large gamme de saveurs et de forces correspondant à vos préférences.",
    og: {
      title: "Rechercher des Produits | SnusCo",
      description:
        "Utilisez la fonction de recherche de SnusCo pour découvrir les sachets de nicotine parfaits pour vous. Filtrez par saveur, force, et plus encore.",
    },
  },
  hu: {
    lang: "hu",
    title: "Termékek keresése | SnusCo",
    description:
      "Keresse meg a legjobb nikotinos tasakokat keresőeszközünkkel. Böngésszen a széles íz- és erősségválaszték között, hogy megtalálja az Önnek megfelelőt.",
    og: {
      title: "Termékek keresése | SnusCo",
      description:
        "Használja a SnusCo keresőfunkcióját, hogy felfedezze az Önnek megfelelő nikotinos tasakokat. Szűrjön íz, erősség és további szempontok szerint.",
    },
  },
  tr: {
    lang: "tr",
    title: "Ürünleri Ara | SnusCo",
    description:
      "Arama aracımızla en iyi nikotin torbalarını bulun. Tercihlerinize göre geniş aroma ve güç seçenekleri arasında gezin.",
    og: {
      title: "Ürünleri Ara | SnusCo",
      description:
        "SnusCo'nun arama özelliğini kullanarak size en uygun nikotin torbalarını keşfedin. Aroma, güç ve daha fazlasına göre filtreleyin.",
    },
  },
  sr: {
    lang: "sr",
    title: "Pretraga Proizvoda | SnusCo",
    description:
      "Pronađite najbolje nikotinske kesice pomoću našeg alata za pretragu. Pretražujte širok spektar ukusa i jačina prema vašim preferencijama.",
    og: {
      title: "Pretraga Proizvoda | SnusCo",
      description:
        "Koristite funkciju pretrage na SnusCo da biste otkrili savršene nikotinske kesice za vas. Filtrirajte prema ukusu, jačini i drugim opcijama.",
    },
  },
};

const Search = () => {
  const { t, i18n } = useTranslation();
  const searchParams = new URLSearchParams(window.location.search);
  const searchTerm = searchParams.get("q");
  const [seo, setSeo] = useState(SEOConfig[DEFAULT_LANGUAGE]);

  useEffect(() => {
    setSeo(SEOConfig[i18n.language] || SEOConfig[DEFAULT_LANGUAGE]);
  }, [i18n.language]);
  return (
    <Container>
      <Helmet>
        <html lang={seo.lang} />
        <title>{seo.title}</title>
        <link
          rel="alternate"
          href={`https://www.snusco.com/en/search?q=${searchTerm}`}
          hreflang={seo.lang}
        />
        <link
          rel="alternate"
          href={`https://www.snusco.com/de/search?q=${searchTerm}`}
          hreflang="de"
        />
        <link
          rel="alternate"
          href={`https://www.snusco.com/it/search?q=${searchTerm}`}
          hreflang="it"
        />
        <link
          rel="alternate"
          href={`https://www.snusco.com/fr/search?q=${searchTerm}`}
          hreflang="fr"
        />
        <link
          rel="alternate"
          href={`https://www.snusco.com/hu/search?q=${searchTerm}`}
          hreflang="hu"
        />
        <link
          rel="alternate"
          href={`https://www.snusco.com/tr/search?q=${searchTerm}`}
          hreflang="tr"
        />
        <link
          rel="alternate"
          href={`https://www.snusco.com/sr/search?q=${searchTerm}`}
          hreflang="sr"
        />
        <link
          rel="alternate"
          href={`https://www.snusco.com/search?q=${searchTerm}`}
          hreflang="x-default"
        />

        <meta name="description" content={seo.description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={seo.og.title} />
        <meta property="og:description" content={seo.og.description} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://www.snusco.com/search?q=${searchTerm}`}
        />
        <meta
          property="og:image"
          content="https://www.snusco.com/assets/og-image.jpg"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="SnusCo" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <PrimaryHeader />
      <SearchMain />
      <FooterMain />
    </Container>
  );
};

export default Search;
