import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import PrimaryHeader from "../layouts/header/PrimaryHeader";
import { useEffect, useState } from "react";
import FooterMain from "../layouts/footer/FooterMain";
import BlogsMain from "../layouts/main/Blogs";
import { DEFAULT_LANGUAGE } from "../utils/global_const";
const Container = styled.div`
  background-color: var(--background-color);
  color: var(--text-color);
`;
const SEOConfig = {
  en: {
    lang: "en",
    title: "Blogs | SnusCo",
    description:
      "Stay updated with the latest news, trends, and tips in the world of nicotine pouches. Explore our blog for valuable insights.",
    og: {
      title: "Blogs | SnusCo",
      description:
        "Read the latest articles on nicotine pouches, including trends, tips, and industry news on the SnusCo blog.",
    },
  },
  de: {
    lang: "de",
    title: "Blogs | SnusCo",
    description:
      "Bleiben Sie auf dem Laufenden mit den neuesten Nachrichten, Trends und Tipps in der Welt der Nikotinbeutel. Entdecken Sie unseren Blog für wertvolle Einblicke.",
    og: {
      title: "Blogs | SnusCo",
      description:
        "Lesen Sie die neuesten Artikel über Nikotinbeutel, einschließlich Trends, Tipps und Branchennews im SnusCo-Blog.",
    },
  },
  it: {
    lang: "it",
    title: "Blog | SnusCo",
    description:
      "Rimani aggiornato con le ultime notizie, tendenze e consigli nel mondo delle pouches di nicotina. Esplora il nostro blog per approfondimenti preziosi.",
    og: {
      title: "Blog | SnusCo",
      description:
        "Leggi gli ultimi articoli sulle pouches di nicotina, comprese tendenze, consigli e notizie del settore sul blog di SnusCo.",
    },
  },
  fr: {
    lang: "fr",
    title: "Blogs | SnusCo",
    description:
      "Restez informé des dernières actualités, tendances et conseils dans le monde des sachets de nicotine. Explorez notre blog pour des insights précieux.",
    og: {
      title: "Blogs | SnusCo",
      description:
        "Lisez les derniers articles sur les sachets de nicotine, y compris les tendances, les conseils et les nouvelles de l'industrie sur le blog SnusCo.",
    },
  },
  hu: {
    lang: "hu",
    title: "Blogok | SnusCo",
    description:
      "Maradjon naprakész a legfrissebb hírekkel, trendekkel és tippekkel a nikotinos tasakok világában. Fedezze fel blogunkat értékes betekintésekért.",
    og: {
      title: "Blogok | SnusCo",
      description:
        "Olvassa el a legújabb cikkeket a nikotinos tasakokról, beleértve a trendeket, tippeket és iparági híreket a SnusCo blogon.",
    },
  },
  tr: {
    lang: "tr",
    title: "Bloglar | SnusCo",
    description:
      "Nikotin torbaları dünyasındaki en son haberler, trendler ve ipuçlarıyla güncel kalın. Değerli bilgiler için blogumuzu keşfedin.",
    og: {
      title: "Bloglar | SnusCo",
      description:
        "Nikotin torbaları hakkında en son makaleleri, trendleri, ipuçlarını ve sektörel haberleri SnusCo blogunda okuyun.",
    },
  },
  sr: {
    lang: "sr",
    title: "Blogovi | SnusCo",
    description:
      "Budite u toku sa najnovijim vestima, trendovima i savetima u svetu nikotinskih kesica. Istražite naš blog za vredne uvide.",
    og: {
      title: "Blogovi | SnusCo",
      description:
        "Pročitajte najnovije članke o nikotinskim kesicama, uključujući trendove, savete i novosti iz industrije na SnusCo blogu.",
    },
  },
};
const Blogs = () => {
  const { t, i18n } = useTranslation();
  const [seo, setSeo] = useState(SEOConfig[DEFAULT_LANGUAGE]);

  useEffect(() => {
    setSeo(SEOConfig[i18n.language] || SEOConfig[DEFAULT_LANGUAGE]);
  }, [i18n.language]);
  return (
    <Container>
      <Helmet>
        <html lang={seo.lang} />
        <title>{seo.title}</title>
        <link
          rel="alternate"
          href="http://www.snusco.com/en/blogs"
          hreflang={seo.lang}
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/de/blogs"
          hreflang="de"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/it/blogs"
          hreflang="it"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/fr/blogs"
          hreflang="fr"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/hu/blogs"
          hreflang="hu"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/tr/blogs"
          hreflang="tr"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/sr-latn/blogs"
          hreflang="sr-latn"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/blogs"
          hreflang="x-default"
        />

        <meta name="description" content={seo.description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={seo.og.title} />
        <meta property="og:description" content={seo.og.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://www.snusco.com/blogs" />
        <meta
          property="og:image"
          content="http://www.snusco.com/assets/og-image.jpg"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="SnusCo" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <PrimaryHeader />
      <BlogsMain />
      <FooterMain />
    </Container>
  );
};

export default Blogs;
