export const DOMAIN = "snusco.com";
export const convertCurrency = (price, fromCurrency, toCurrency) => {
  if (fromCurrency === toCurrency) {
    // Nema potrebe za konverzijom ako su valute iste
    return price;
  }

  let exchangeRate = 1; // Postavite početni kurs razmene

  // Direktan kurs razmene iz EUR u USD i obrnuto
  const rates = {
    "EUR->USD": 1.1,
    "USD->EUR": 1 / 1.1,
  };

  // Formiranje ključa za pristup odgovarajućem kursu razmene
  const rateKey = `${fromCurrency}->${toCurrency}`;

  // Ako postoji definisan kurs razmene za dati par valuta, koristite ga
  if (rates[rateKey]) {
    exchangeRate = rates[rateKey];
  }

  // Vršite konverziju cene koristeći odabrani kurs razmene
  let convertedPrice = price * exchangeRate;

  // Vraćanje konvertovane cene bez dodatnog zaokruživanja
  // Ovo može pomoći u smanjenju akumulacije grešaka prilikom višestrukih konverzija
  return convertedPrice;
};
export const currencyTags = {
  USD: "$",
  EUR: "€",
};
export const DEFAULT_CURRENCY = "EUR";

export const PRODUCT_CHUNK = 48; // koliko se prozivoda prikazuje odjednom

//CHECKOUT
export const paymentMethods = [
  { name: "Cash on Delivery" },
  { name: "Credit Card" },
  //{ name: "Paypal" },
];

export const transportMethods = [
  { name: "UPS Standard", price: 4.9 },
  { name: "Postnord", price: 5.9 },
  { name: "UPS Express Saver", price: 19.9 },
];

export const freeShippingThreshold = 200; // Prag za besplatnu dostavu

export const getShippingCostPrice = (fromCurrency, toCurrency) => {
  const price = convertCurrency(
    transportMethods[0].price,
    fromCurrency,
    toCurrency
  );
  return price;
};
export const DEFAULT_LANGUAGE = "en";
export const languages = [
  {
    code: "en",
    label: "American English (Default)",
    flag: require("../assets/flags/svg/us.svg").default, // Pretpostavljamo da imate zastavu SAD za američki engleski
  },
  {
    code: "de",
    label: "German",
    flag: require("../assets/flags/svg/de.svg").default,
  },
  {
    code: "it",
    label: "Italian",
    flag: require("../assets/flags/svg/it.svg").default,
  },
  {
    code: "fr",
    label: "French",
    flag: require("../assets/flags/svg/fr.svg").default,
  },
  {
    code: "hu",
    label: "Hungarian",
    flag: require("../assets/flags/svg/hu.svg").default,
  },
  {
    code: "tr",
    label: "Turkish",
    flag: require("../assets/flags/svg/tr.svg").default,
  },
  {
    code: "sr",
    label: "Serbian (Latin)",
    flag: require("../assets/flags/svg/rs.svg").default,
  },
];

export const adminEmailList = [
  "djuricm13111@gmail.com",
  "djuricm888@gmail.com",
];
