import React, { useContext, useState } from "react";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";
import ProductCardBG from "./ProductCardBG";
import { ProductContext } from "../../context/ProductContext";
import { useTranslation } from "react-i18next";

const Container = styled.div``;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--background-color-hover);
`;
const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 94%;
  @media (min-width: 768px) {
    width: 100%;
    max-width: var(--max-width-container);
  }
`;
const Flex = styled.div`
  display: flex;
  align-content: center;
  gap: var(--spacing-md);
`;
const Title = styled.h2`
  text-align: center;
  text-transform: uppercase;

  @media (min-width: 768px) {
    text-align: left;
  }
`;
const SliderContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 24px 0;

  //height: 200px;
  @media (min-width: 768px) {
    max-width: var(--max-width-container);
  }
`;

const SliderContent = styled.div`
  display: flex;
  transform: translateX(-${(props) => props.$currentindex * 80}%);
  transition: transform 0.3s ease-out;
  @media (min-width: 768px) {
    transform: translateX(-${(props) => props.$currentindex * 30}%);
  }
  @media (min-width: 1024px) {
    transform: translateX(-${(props) => props.$currentindex * 25}%);
  }
`;
const SliderItem = styled.div`
  flex: 0 0 auto; // Sprečava rastezanje elemenata
  width: 80%; // Širina pojedinačnih elemenata
  margin-right: 4px; // Dodaje prostor između elemenata
  //border: 2px solid black;
  @media (min-width: 768px) {
    width: 30%;
  }
  @media (min-width: 1024px) {
    width: 25%;
  }
`;
const Button = styled.div`
  padding: 10px;
  cursor: pointer;

  border: 1px solid black;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

const HorizontalSwipeSlider = ({ items }) => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlers = useSwipeable({
    onSwipedLeft: () =>
      setCurrentIndex((prevIndex) => {
        const maxIndex = getMaxIndex(window.innerWidth, items.length);
        return Math.min(prevIndex + 1, maxIndex);
      }),
    onSwipedRight: () =>
      setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0)),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const getMaxIndex = (windowWidth, itemsLength) => {
    if (windowWidth >= 1024) {
      // Pretpostavka za tablet/desktop širinu
      const visibleItems = 4; // Broj elemenata koji staju na ekran
      const maxIndex = Math.ceil(
        itemsLength - visibleItems < 0 ? 0 : itemsLength - visibleItems
      );
      return maxIndex;
    } else if (windowWidth >= 768) {
      // Pretpostavka za tablet/desktop širinu
      const visibleItems = 2; // Broj elemenata koji staju na ekran
      const maxIndex = Math.ceil(
        itemsLength - visibleItems < 0 ? 0 : itemsLength - visibleItems
      );
      return maxIndex;
    }
    return itemsLength - 1; // Za mobilne uređaje, pretpostavka je da se prikazuje 1 element
  };
  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => {
      const maxIndex = getMaxIndex(window.innerWidth, items.length);

      return Math.min(prevIndex + 1, maxIndex);
    });
  };
  const maxIndex = getMaxIndex(window.innerWidth, items.length);

  return (
    <Container>
      <Wrapper>
        <FlexDiv>
          <Title>{t("PRODUCT.SIMILAR_PRODUCTS")}</Title>
          <Flex>
            <Button
              onClick={handlePrevClick}
              className={currentIndex === 0 ? "disabled" : ""}
            >
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 1L1 7L7 13"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
            <Button
              onClick={handleNextClick}
              className={currentIndex === maxIndex ? "disabled" : ""}
            >
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ transform: "rotate(-180deg)" }}
              >
                <path
                  d="M7 1L1 7L7 13"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
          </Flex>
        </FlexDiv>
        <SliderContainer {...handlers}>
          <SliderContent $currentindex={currentIndex}>
            {items &&
              items.length &&
              items.map((item, index) => (
                <SliderItem key={index}>
                  <ProductCardBG product={item} />
                </SliderItem>
              ))}
          </SliderContent>
        </SliderContainer>
      </Wrapper>
    </Container>
  );
};

export default HorizontalSwipeSlider;

//DOSTA ERRORA NA KONZOLI PROUZRUKUJE OVO
