import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { generateCategoryData } from "../../../utils/menu_data";
import { Link, useNavigate } from "react-router-dom";
import { ProductContext } from "../../../context/ProductContext";
import { useNavigation } from "../../../utils/navigation";
import { useTranslation } from "react-i18next";
const Container = styled.div``;
const CategoryContainer = styled.div`
  position: relative;
  font-weight: 500;
  font-size: var(--font-size-base);
  font-family: Satoshi;
  z-index: 2;
`;
const List = styled.div`
  padding: 0;
  border: 0;
  outline: none;
  list-style: none;
  position: absolute;
  right: 100%;
  top: calc(100% + 30px);

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: var(--background-color);
  border: 1px solid var(--background-color-hover);
  gap: 12px;
  padding: 12px;
  box-shadow: var(--shadow-large);
  max-height: calc(100vh - var(--navbar-height-desktop) - 30px);
  overflow: auto; /* omogućava skrolanje unutar div-a */
  -webkit-overflow-scrolling: touch;
`;
const MaskDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 1;
`;
const ListItem = styled.div`
  text-decoration: none;
  color: var(--text-color);
  width: 200px;
  padding: 24px 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover svg {
    opacity: 1;
  }
  background: linear-gradient(284deg, #fef8f4 1.78%, #ecf4f9 87.76%);
  &:hover {
    //background-color: var(--background-color-hover-alt);

    box-shadow: 4px 4px 1.5px 0px rgba(82, 82, 82, 0.12);
  }
`;
const Icon = styled.svg`
  opacity: 0;
  transition: opacity 0.3s ease;
`;
const Span = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;
const FlexDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;
const Picture = styled.picture`
  background-color: ${({ color }) => color || "#fff"};

  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
const Image = styled.img`
  width: 34px;
  max-width: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
`;
const CategoryList = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { categories } = useContext(ProductContext);
  const navigate = useNavigate();
  const { goToCategory } = useNavigation();
  const containerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  return (
    <>
      {show && <MaskDiv onClick={() => setShow(!show)} />}

      <Container>
        <CategoryContainer
          onClick={() => {
            setShow(!show);
          }}
        >
          <Span>
            {t("HEADER.CATEGORIES")}{" "}
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={show ? { transform: "rotate(180deg)" } : {}}
            >
              <path
                d="M-0.000325203 0.333496L4.66634 5.66683L9.33301 0.333496L-0.000325203 0.333496Z"
                fill="var(--text-color)"
              />
            </svg>
          </Span>
          {show && (
            <List ref={containerRef}>
              {categories.map((category, index) => (
                <ListItem
                  key={index}
                  onClick={() => navigate(goToCategory(category.name))}
                >
                  <FlexDiv>
                    <Picture color={category.color}>
                      <Image
                        src={require(`../../../assets/images/categories/${category.slug}.png`)}
                        alt={category.name}
                        width="34"
                        //height="30"
                      />
                    </Picture>
                    {category.name}
                  </FlexDiv>
                  <Icon
                    width="20px"
                    height="20px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.3153 16.6681C15.9247 17.0587 15.9247 17.6918 16.3153 18.0824C16.7058 18.4729 17.339 18.4729 17.7295 18.0824L22.3951 13.4168C23.1761 12.6357 23.1761 11.3694 22.3951 10.5883L17.7266 5.9199C17.3361 5.52938 16.703 5.52938 16.3124 5.91991C15.9219 6.31043 15.9219 6.9436 16.3124 7.33412L19.9785 11.0002L2 11.0002C1.44772 11.0002 1 11.4479 1 12.0002C1 12.5524 1.44772 13.0002 2 13.0002L19.9832 13.0002L16.3153 16.6681Z"
                      fill="var(--text-color)"
                    />
                  </Icon>
                </ListItem>
              ))}
            </List>
          )}
        </CategoryContainer>
      </Container>
    </>
  );
};

export default CategoryList;
