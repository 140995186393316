import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import MainHeader from "../layouts/header/MainHeader";
import PrimaryHeader from "../layouts/header/PrimaryHeader";
import Footer from "../layouts/footer/Footer";
import HomeMain from "../layouts/main/HomeMain";
import { useContext, useEffect, useState } from "react";
import FooterMain from "../layouts/footer/FooterMain";
import { ProductContext } from "../context/ProductContext";
import { DEFAULT_LANGUAGE } from "../utils/global_const";

const Container = styled.div`
  background-color: var(--background-color);
  color: var(--text-color);
`;

const SEOConfig = {
  en: {
    lang: "en",
    title: "SnusCo | Premium Nicotine Pouches",
    description:
      "Discover the finest selection of nicotine pouches on SnusCo. Enjoy a premium experience with our wide range of flavors and strengths.",
    og: {
      title: "SnusCo | Premium Nicotine Pouches",
      description:
        "Explore the world of nicotine pouches with SnusCo. Find your perfect match from our extensive collection.",
    },
  },
  de: {
    lang: "de",
    title: "SnusCo | Premium Nikotinbeutel",
    description:
      "Entdecken Sie die beste Auswahl an Nikotinbeuteln auf SnusCo. Genießen Sie ein Premium-Erlebnis mit unserer großen Auswahl an Geschmacksrichtungen und Stärken.",
    og: {
      title: "SnusCo | Premium Nikotinbeutel",
      description:
        "Erkunden Sie die Welt der Nikotinbeutel mit SnusCo. Finden Sie Ihren perfekten Match aus unserer umfangreichen Kollektion.",
    },
  },
  it: {
    lang: "it",
    title: "SnusCo | Pouches di Nicotina Premium",
    description:
      "Scopri la selezione più raffinata di pouches di nicotina su SnusCo. Goditi un'esperienza premium con la nostra vasta gamma di sapori e intensità.",
    og: {
      title: "SnusCo | Pouches di Nicotina Premium",
      description:
        "Esplora il mondo delle pouches di nicotina con SnusCo. Trova il tuo abbinamento perfetto dalla nostra vasta collezione.",
    },
  },
  fr: {
    lang: "fr",
    title: "SnusCo | Sachets de Nicotine Premium",
    description:
      "Découvrez la meilleure sélection de sachets de nicotine chez SnusCo. Profitez d'une expérience premium avec notre large gamme de saveurs et de forces.",
    og: {
      title: "SnusCo | Sachets de Nicotine Premium",
      description:
        "Explorez le monde des sachets de nicotine avec SnusCo. Trouvez votre combinaison parfaite dans notre vaste collection.",
    },
  },
  hu: {
    lang: "hu",
    title: "SnusCo | Prémium Nikotinos Tasakok",
    description:
      "Fedezze fel a legjobb nikotinos tasakokat a SnusCo-on. Élvezze a prémium élményt széles íz- és erősségválasztékunkkal.",
    og: {
      title: "SnusCo | Prémium Nikotinos Tasakok",
      description:
        "Fedezze fel a nikotinos tasakok világát a SnusCo-szal. Találja meg tökéletes párosítását széles kínálatunkból.",
    },
  },
  tr: {
    lang: "tr",
    title: "SnusCo | Premium Nikotin Torbaları",
    description:
      "SnusCo'ta en iyi nikotin torbaları seçimini keşfedin. Geniş aroma ve güç yelpazemizle premium bir deneyim yaşayın.",
    og: {
      title: "SnusCo | Premium Nikotin Torbaları",
      description:
        "SnusCo ile nikotin torbaları dünyasını keşfedin. Geniş koleksiyonumuzdan mükemmel eşleşmenizi bulun.",
    },
  },
  "sr-Latn": {
    lang: "sr-latn",
    title: "SnusCo | Premium Nikotinske Kesice",
    description:
      "Otkrijte najfiniji izbor nikotinskih kesica na SnusCo. Uživajte u premium iskustvu sa našim širokim spektrom ukusa i jačina.",
    og: {
      title: "SnusCo | Premium Nikotinske Kesice",
      description:
        "Istražite svet nikotinskih kesica sa SnusCo. Pronađite svoj idealan izbor iz naše obimne kolekcije.",
    },
  },
};

const Home = () => {
  const { t, i18n } = useTranslation();
  const [seo, setSeo] = useState(SEOConfig[DEFAULT_LANGUAGE]);
  const { loadProducts } = useContext(ProductContext);

  useEffect(() => {
    setSeo(SEOConfig[i18n.language] || SEOConfig[DEFAULT_LANGUAGE]);
    //loadProducts();
  }, [i18n.language]);

  return (
    <Container>
      <Helmet>
        <html lang={seo.lang} />
        <title>{seo.title}</title>
        <link
          rel="alternate"
          href="https://www.snusco.com/en"
          hreflang={seo.lang}
        />
        <link rel="alternate" href="https://www.snusco.com/de" hreflang="de" />
        <link rel="alternate" href="https://www.snusco.com/it" hreflang="it" />
        <link rel="alternate" href="https://www.snusco.com/fr" hreflang="fr" />
        <link rel="alternate" href="https://www.snusco.com/hu" hreflang="hu" />
        <link rel="alternate" href="https://www.snusco.com/tr" hreflang="tr" />
        <link rel="alternate" href="https://www.snusco.com/sr" hreflang="sr" />
        <link
          rel="alternate"
          href="https://www.snusco.com/"
          hreflang="x-default"
        />

        <meta name="description" content={seo.description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={seo.og.title} />
        <meta property="og:description" content={seo.og.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.snus.com/" />
        <meta
          property="og:image"
          content="https://www.snus.com/assets/og-image.jpg"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="SnusCo" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {/* <MainHeader /> */}
      <PrimaryHeader />
      <HomeMain />

      {/* <Footer /> */}
      <FooterMain />
    </Container>
  );
};

export default Home;
