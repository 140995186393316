import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import PrimaryHeader from "../layouts/header/PrimaryHeader";
import { useEffect, useState } from "react";
import FooterMain from "../layouts/footer/FooterMain";
import PrivacyPolicyMain from "../layouts/main/PrivacyPolicy";
import { DEFAULT_LANGUAGE } from "../utils/global_const";
const Container = styled.div`
  background-color: var(--background-color);
  color: var(--text-color);
`;
const SEOConfig = {
  en: {
    lang: "en",
    title: "Privacy Policy | SnusCo",
    description:
      "Learn how SnusCo protects your privacy. Read our privacy policy to understand how we collect, use, and safeguard your information.",
    og: {
      title: "Privacy Policy | SnusCo",
      description:
        "Understand our commitment to your privacy at SnusCo. Read our policy on data collection, usage, and protection.",
    },
  },
  de: {
    lang: "de",
    title: "Datenschutzrichtlinie | SnusCo",
    description:
      "Erfahren Sie, wie SnusCo Ihre Privatsphäre schützt. Lesen Sie unsere Datenschutzrichtlinie, um zu verstehen, wie wir Ihre Daten sammeln, verwenden und schützen.",
    og: {
      title: "Datenschutzrichtlinie | SnusCo",
      description:
        "Verstehen Sie unser Engagement für Ihre Privatsphäre bei SnusCo. Lesen Sie unsere Richtlinie zur Datenerhebung, -nutzung und -schutz.",
    },
  },
  it: {
    lang: "it",
    title: "Politica sulla Privacy | SnusCo",
    description:
      "Scopri come SnusCo protegge la tua privacy. Leggi la nostra politica sulla privacy per capire come raccogliamo, utilizziamo e proteggiamo le tue informazioni.",
    og: {
      title: "Politica sulla Privacy | SnusCo",
      description:
        "Comprendi il nostro impegno per la tua privacy su SnusCo. Leggi la nostra politica sulla raccolta, l'uso e la protezione dei dati.",
    },
  },
  fr: {
    lang: "fr",
    title: "Politique de Confidentialité | SnusCo",
    description:
      "Découvrez comment SnusCo protège votre vie privée. Lisez notre politique de confidentialité pour comprendre comment nous collectons, utilisons et protégeons vos informations.",
    og: {
      title: "Politique de Confidentialité | SnusCo",
      description:
        "Comprenez notre engagement envers votre vie privée chez SnusCo. Lisez notre politique sur la collecte, l'utilisation et la protection des données.",
    },
  },
  hu: {
    lang: "hu",
    title: "Adatvédelmi Irányelvek | SnusCo",
    description:
      "Ismerje meg, hogyan védi a SnusCo az Ön magánéletét. Olvassa el adatvédelmi irányelveinket, hogy megértse, hogyan gyűjtjük, használjuk és védjük az Ön adatait.",
    og: {
      title: "Adatvédelmi Irányelvek | SnusCo",
      description:
        "Értse meg elkötelezettségünket az Ön adatainak védelme iránt a SnusCo-nál. Olvassa el adatvédelmi irányelveinket az adatgyűjtésről, felhasználásról és védelemről.",
    },
  },
  tr: {
    lang: "tr",
    title: "Gizlilik Politikası | SnusCo",
    description:
      "SnusCo'nun gizliliğinizi nasıl koruduğunu öğrenin. Bilgilerinizi nasıl topladığımızı, kullandığımızı ve koruduğumuzu anlamak için gizlilik politikamızı okuyun.",
    og: {
      title: "Gizlilik Politikası | SnusCo",
      description:
        "SnusCo'da gizliliğinize olan bağlılığımızı anlayın. Veri toplama, kullanma ve koruma politikamızı okuyun.",
    },
  },
  sr: {
    lang: "sr",
    title: "Politika Privatnosti | SnusCo",
    description:
      "Saznajte kako SnusCo štiti vašu privatnost. Pročitajte našu politiku privatnosti da biste razumeli kako prikupljamo, koristimo i štitimo vaše informacije.",
    og: {
      title: "Politika Privatnosti | SnusCo",
      description:
        "Razumite našu posvećenost zaštiti vaše privatnosti na SnusCo. Pročitajte našu politiku o prikupljanju, korišćenju i zaštiti podataka.",
    },
  },
};

const Blogs = () => {
  const { t, i18n } = useTranslation();
  const [seo, setSeo] = useState(SEOConfig[DEFAULT_LANGUAGE]);

  useEffect(() => {
    setSeo(SEOConfig[i18n.language] || SEOConfig[DEFAULT_LANGUAGE]);
  }, [i18n.language]);
  return (
    <Container>
      <Helmet>
        <html lang={seo.lang} />
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={seo.og.title} />
        <meta property="og:description" content={seo.og.description} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.snusco.com/privacy-policy"
        />
        <meta
          property="og:image"
          content="https://www.snusco.com/assets/og-image.jpg"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="SnusCo" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <PrimaryHeader />
      <PrivacyPolicyMain />
      <FooterMain />
    </Container>
  );
};

export default Blogs;
