import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import HamburgerMenuLeftData from "./HamburgerMenuLeftData";

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
`;

const slideOutLeft = keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
`;
const ContainerX = styled.div``;
const Container = styled.div`
  margin-top: var(--spacing-xxs);
  background-color: var(--transparent-color);
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--zindex-default);
  min-width: 100%;
  height: 100vh;

  box-sizing: border-box;
  overflow-x: hidden;
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  visibility: ${(props) => (props.$shouldBeVisible ? "visible" : "hidden")};
`;
const Wrapper = styled.div`
  background-color: var(--background-color);

  width: 80%;
  height: 100%;
  min-height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          animation: ${slideInFromLeft} 0.5s ease-out forwards; // Duže trajanje za slide-in
        `
      : css`
          animation: ${slideOutLeft} 0.2s ease-in forwards; // Kraće trajanje za slide-out
        `}
`;
// Styled komponenta za hamburger linije
const HamburgerIcon = styled.div`
  width: var(--font-size-xlarge);
  height: 2px;
  background-color: var(--text-color);
  transition: all 0.5s ease-in-out;
  transform-origin: 1px;

  &:nth-child(1) {
    transform: ${({ $isOpen }) => ($isOpen ? "rotate(45deg)" : "rotate(0)")};
    width: 25px;
  }

  &:nth-child(2) {
    transform: ${({ $isOpen }) =>
      $isOpen ? "translateX(100%)" : "translateX(0)"};
    opacity: ${({ $isOpen }) => ($isOpen ? 0 : 1)};
  }

  &:nth-child(3) {
    transform: ${({ $isOpen }) => ($isOpen ? "rotate(-45deg)" : "rotate(0)")};
    width: 25px;
  }
`;
const HamburgerMidIcon = styled(HamburgerIcon)`
  width: calc(var(--font-size-xlarge) - 5px);
  height: 1.8px;
`;

// Styled komponenta za kontejner koji drži hamburger ikonu
const HamburgerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 25px;
  cursor: pointer;
`;
const Span = styled.span`
  font-weight: 500;
  font-size: var(--font-size-small);
`;
const XDiv = styled.div`
  padding: 0 var(--spacing-md);
  cursor: pointer;
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-md);
`;
const MiddleDiv = styled.div``;

const Logo = styled.div`
  color: var(--text-color);

  font-size: var(--font-size-xlarge);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.438px;
  display: flex;
  gap: var(--spacing-xs);

  align-items: center;
  cursor: pointer;
`;

const HamburgerMenuLeft = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [shouldBeVisible, setShouldBeVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShouldBeVisible(true);
    } else {
      const timer = setTimeout(() => {
        setShouldBeVisible(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ContainerX>
      <HamburgerContainer onClick={toggleMenu}>
        <HamburgerIcon key="1" $isOpen={isOpen} />
        <HamburgerMidIcon key="2" $isOpen={isOpen} />
        <HamburgerIcon key="3" $isOpen={isOpen} />
      </HamburgerContainer>

      <Container
        onClick={toggleMenu}
        $isOpen={isOpen}
        $shouldBeVisible={shouldBeVisible}
      >
        <Wrapper
          $isOpen={isOpen}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <XDiv>
            <Logo>
              <svg
                width="40"
                height="40"
                viewBox="0 0 298 298"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M148.707 0C66.7245 0 0 66.7245 0 148.707C0 230.689 66.7245 297.414 148.707 297.414C230.689 297.414 297.414 230.689 297.414 148.707C297.414 66.7245 230.689 0 148.707 0ZM294.707 148.707C294.707 188.275 278.843 224.204 253.18 250.567L244.688 242.074C268.158 217.904 282.669 184.962 282.669 148.707C282.669 112.452 267.878 78.8562 243.988 54.6394L252.48 46.1472C278.563 72.557 294.661 108.766 294.661 148.707H294.707ZM17.3576 148.707C17.3576 76.2899 76.2432 17.4043 148.66 17.4043C221.077 17.4043 279.963 76.2899 279.963 148.707C279.963 221.124 221.077 280.009 148.66 280.009C76.2432 280.009 17.3576 221.124 17.3576 148.707ZM250.52 44.2808L242.028 52.7731C217.858 29.2562 184.915 14.7447 148.614 14.7447C112.312 14.7447 79.416 29.2562 55.2458 52.7731L46.7536 44.2808C73.0701 18.6176 108.999 2.75295 148.567 2.75295C188.135 2.75295 224.11 18.6175 250.427 44.3275L250.52 44.2808ZM44.7939 46.1939L53.2862 54.6862C29.3494 78.9029 14.558 112.172 14.558 148.8C14.558 185.429 29.0693 218.044 52.5862 242.214L44.1406 250.707C18.4307 224.39 2.56627 188.415 2.56627 148.847C2.56627 109.279 18.7107 72.6504 44.7939 46.2872V46.1939ZM46.0539 252.667L54.5461 244.174C78.7629 268.064 111.985 282.856 148.614 282.856C185.242 282.856 218.511 268.064 242.728 244.128L251.22 252.62C224.857 278.703 188.602 294.848 148.66 294.848C108.719 294.848 72.5102 278.703 46.147 252.667"
                  fill="var(--text-color)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M207.722 57.7351C202.622 66.9627 200.922 78.8759 205.416 87.3302C214.54 104.491 230.158 114.314 239.894 131.851C273.15 191.781 209.011 201.72 174.784 209.915C155.428 214.537 143.812 230.506 158.492 258.688C214.436 253.98 258.39 207.07 258.39 149.903C258.39 111.144 238.177 77.1018 207.722 57.7266"
                  fill="var(--text-color)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M191.927 105.506C177.373 95.2564 162.525 82.7355 160.972 63.8935C160.307 55.8558 164.875 48.4915 171.632 43.0459C157.459 40.0907 142.84 39.9776 128.623 42.713C114.406 45.4483 100.873 50.9779 88.8086 58.9812C96.9207 91.2874 127.561 94.6763 155.629 103.143C195.722 115.235 214.322 153.656 158.871 159.818C81.4401 168.423 72.2954 187.545 127.861 256.987C133.447 258.095 139.112 258.763 144.803 258.984C114.222 212.039 131.18 198.9 188.95 180.965C245.166 163.485 224.646 128.565 191.925 105.51"
                  fill="var(--text-color)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M123.936 118.509C100.162 114.213 67.312 112.348 58.2119 97.5595C57.3549 96.166 56.5939 94.7157 55.9342 93.2188C45.5264 110.296 40.0342 129.915 40.0626 149.915C40.0626 197.952 71.1131 238.761 114.236 253.341C95.0693 233.567 63.4812 210.21 71.1047 172.043C76.204 146.547 105.038 150.269 127.675 148.404C160.298 145.722 156.82 124.463 123.927 118.509"
                  fill="var(--text-color)"
                />
              </svg>
              SnusCo
            </Logo>
            <svg
              aria-hidden="true"
              focusable="false"
              role="presentation"
              viewBox="0 0 64 64"
              className="svg-icon"
              style={{
                width: "calc(var(--font-size-xxlarge) + 5px)",
                height: "calc(var(--font-size-xxlarge) + 5px)",
              }}
              onClick={toggleMenu}
            >
              <path d="M19 17.61l27.12 27.13m0-27.12L19 44.74"></path>
            </svg>
          </XDiv>
          <MiddleDiv>
            <HamburgerMenuLeftData setIsOpen={setIsOpen} />{" "}
          </MiddleDiv>
        </Wrapper>
      </Container>
    </ContainerX>
  );
};

export default HamburgerMenuLeft;
