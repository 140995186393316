import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/translation.json";
import translationDE from "./locales/de/translation.json";
import translationIT from "./locales/it/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationHU from "./locales/hu/translation.json";
import translationTR from "./locales/tr/translation.json";
import translationSR from "./locales/sr/translation.json";

const resources = {
  en: { translation: translationEN },
  de: { translation: translationDE },
  it: { translation: translationIT },
  fr: { translation: translationFR },
  hu: { translation: translationHU },
  tr: { translation: translationTR },
  sr: { translation: translationSR },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    //lng: "en",
    fallbackLng: "en",
    interpolation: { escapeValue: false },
    detection: {
      order: ["path", "navigator"],
      caches: [],
    },
  });

export default i18n;
