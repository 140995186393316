import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { toast } from "react-toastify";
import Warning from "../toast/Warning";
import { useNavigation } from "../../utils/navigation";
import { AuthUserContext } from "../../context/AuthUserContext";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--background-color);
`;
const RadioButton = styled.div`
  margin: var(--spacing-md);
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  border-radius: 20px;
  border: 1px solid var(--primary-color);

  display: none;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const CheckedRadio = styled.div`
  background-color: var(--primary-color);
  width: 8px;
  height: 8px;
  border-radius: 20px;
`;

const LeftDiv = styled.div`
  width: 100%;
  padding: var(--spacing-md);
`;

const PasswordTitle = styled.h3`
  font-weight: 500;
  font-size: var(--font-size-large);
  text-transform: uppercase;
`;

const P = styled.p`
  font-size: var(--font-size-small);
  line-height: var(--font-size-large);
  font-weight: 500;
`;

const AddressType = styled.p`
  font-size: var(--font-size-small);
  font-family: "Larken";
  margin-top: 0;
  border: 1px solid var(--p-color);
  padding: var(--spacing-xs) var(--spacing-md);
  border-radius: 20px;
  text-transform: capitalize;
  color: ${({ $isActive }) =>
    $isActive ? "var(--accent-color)" : "var(--text-color)"};

  border-color: ${({ $isActive }) =>
    $isActive ? "var(--accent-color)" : "var(--p-color)"};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 767px) {
    width: 90%;
  }
`;
const FormDiv = styled.div`
  width: 100%;
  min-width: 100%;
  @media (min-width: 767px) {
    display: flex;
    gap: 24px;
  }
`;
const FormPart = styled.div``;
const FormFlex = styled.div`
  display: flex;
  gap: var(--spacing-xxs);
  width: calc(100%);
  align-items: flex-start;

  @media (min-width: 767px) {
    width: calc(80% + 8px);
    gap: var(--spacing-md);
  }
`;

const Input = styled.input`
  flex: 3;
  width: 100%;
  margin-bottom: var(--spacing-md);
  padding: 14px 0 14px 8px;
  border: 1px solid #d0d5dd;
  &::placeholder {
  }
  &:disabled {
    background-color: var(--background-color);
    cursor: not-allowed;
  }
`;
const SmallInput = styled(Input)`
  flex: 1;
  width: 100%;
`;
const SmallInputDesktop = styled(SmallInput)`
  display: none;
  @media (min-width: 767px) {
    display: block;
  }
`;
const SmallInputMB = styled(SmallInput)`
  display: block;
  @media (min-width: 767px) {
    display: none;
  }
`;

const Button = styled.button`
  background-color: var(--text-color);
  border-radius: 0;
  width: 100%;
  @media (min-width: 767px) {
    width: 200px;
  }
  &:hover {
    background-color: var(--background-color);
    color: var(--text-color);
  }
`;
const CancelButton = styled(Button)`
  width: auto;
  background-color: var(--background-color);
  border: none;
  color: var(--text-color);
  &:hover {
    color: var(--background-color);
    background-color: var(--text-color);
  }
  display: none;
  @media (min-width: 768px) {
    display: inline-block;
  }
`;
const LocationButton = styled.button`
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-xs);
  margin: var(--spacing-md) 0;
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
  }
`;

const showWarningNotification = (text, smalltext) => {
  toast(<Warning text={text} />, {
    position: "top-left",
    autoClose: 5000, // Obaveštenje će se automatski skloniti nakon 5 sekundi
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "custom-toast",
    progressClassName: "custom-progress-bar",
  });
};
const AddressBookForm = ({
  editAddress,
  setSelectedAddress,
  setNewAddress,
  setEditAddress,
}) => {
  const { t } = useTranslation();
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [street, setStreet] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [secondaryStreet, setSecondaryStreet] = useState("");
  const [buildingNumber, setBuildingNumber] = useState("");
  const [latitude, setLatitude] = useState(null); // Možda će biti potrebno unositi kao string, pa konvertovati u broj
  const [longitude, setLongitude] = useState(null); // Isto kao i za latitude

  const [address, setAddress] = useState(null);
  const [type, setType] = useState("Home");
  const [phoneNumber, setPhoneNumber] = useState("");
  const { userProfile, updateAddressBook, createAddressBook } =
    useContext(AuthUserContext);

  useEffect(() => {
    if (userProfile && userProfile.phone_number && !editAddress) {
      setPhoneNumber(userProfile.phone_number);
    }
  }, [userProfile]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Ovde dodajte logiku za validaciju i ažuriranje adrese
    const addressData = {
      country,
      city,
      postal_code: postalCode, // Osigurajte da ključevi odgovaraju onima u serializeru na backendu
      street,
      street_number: streetNumber,
      secondary_street: secondaryStreet || null, // Postavlja na null ako je prazan string
      building_number: buildingNumber || null,
      latitude: latitude ? parseFloat(latitude) : null, // Pretvorite u broj ako je potrebno
      longitude: longitude ? parseFloat(longitude) : null,
      is_primary: true,
      phone_number: phoneNumber,
      type: type,
    };

    //console.log("Podaci o adresi za ažuriranje:", addressData);

    try {
      // Pretpostavljamo da `addressId` i `updateAddress` funkcija postoje
      if (!editAddress) {
        const createdAddress = await createAddressBook(addressData);
        setSelectedAddress(createdAddress);
        handleCancelClick();
      } else {
        const updatedAddress = await updateAddressBook(
          editAddress.id,
          addressData
        );
        setSelectedAddress(updatedAddress);
        handleCancelClick();
      }
    } catch (error) {
      showWarningNotification(t("NOTIFICATIONS.ADDRESS_UPDATE_ERROR"));
    }
  };

  useEffect(() => {
    if (editAddress) {
      setAddress(editAddress);
      setPhoneNumber(editAddress.phone_number);
      setCountry(editAddress.country || "");
      setCity(editAddress.city || "");
      setPostalCode(editAddress.postal_code || "");
      setStreet(editAddress.street || "");
      setStreetNumber(editAddress.street_number || "");
      setSecondaryStreet(editAddress.secondary_street || "");
      setBuildingNumber(editAddress.building_number || "");
      setType(editAddress.type);
    } else {
      setAddress(null);
      setCountry("");
      setCity("");
      setPostalCode("");
      setStreet("");
      setStreetNumber("");
      setSecondaryStreet("");
      setBuildingNumber("");
      setType("Home");
    }
  }, [editAddress]);

  const handleCancelClick = () => {
    setSelectedAddress(null);
    setNewAddress(false);
    if (editAddress) {
      setEditAddress(null);
    }
  };
  return (
    <Container>
      <RadioButton>
        <CheckedRadio />
      </RadioButton>
      <LeftDiv>
        {editAddress ? (
          <PasswordTitle>{t("ADDRESS_BOOK_FORM.EDIT_ADDRESS")}</PasswordTitle>
        ) : (
          <PasswordTitle>
            {t("ADDRESS_BOOK_FORM.ADD_NEW_ADDRESS")}
          </PasswordTitle>
        )}
        <LocationButton>
          {" "}
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_326_5458)">
              <path
                d="M9.2124 12.5C9.2124 13.2956 9.52847 14.0587 10.0911 14.6213C10.6537 15.1839 11.4168 15.5 12.2124 15.5C13.0081 15.5 13.7711 15.1839 14.3337 14.6213C14.8963 14.0587 15.2124 13.2956 15.2124 12.5C15.2124 11.7044 14.8963 10.9413 14.3337 10.3787C13.7711 9.81607 13.0081 9.5 12.2124 9.5C11.4168 9.5 10.6537 9.81607 10.0911 10.3787C9.52847 10.9413 9.2124 11.7044 9.2124 12.5Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.2124 12.5C4.2124 14.6217 5.05526 16.6566 6.55555 18.1569C8.05584 19.6571 10.0907 20.5 12.2124 20.5C14.3341 20.5 16.369 19.6571 17.8693 18.1569C19.3695 16.6566 20.2124 14.6217 20.2124 12.5C20.2124 10.3783 19.3695 8.34344 17.8693 6.84315C16.369 5.34285 14.3341 4.5 12.2124 4.5C10.0907 4.5 8.05584 5.34285 6.55555 6.84315C5.05526 8.34344 4.2124 10.3783 4.2124 12.5Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.2124 2.5V4.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.2124 20.5V22.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.2124 12.5H22.2124"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.2124 12.5H4.2124"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_326_5458">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0.212402 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          {t("ADDRESS_BOOK_FORM.USE_MY_CURRENT_LOCATION")}
        </LocationButton>
        <Form onSubmit={handleSubmit}>
          <FormPart>
            {userProfile && (
              <P>
                {userProfile.first_name} {userProfile.last_name}
              </P>
            )}

            <FormFlex>
              <Input
                type="text"
                placeholder={t("LABELS.PHONE_NUMBER")}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
              <Input
                type="text"
                placeholder={t("LABELS.COUNTRY")}
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                required
              />
            </FormFlex>
            <FormFlex>
              <SmallInput
                type="number"
                placeholder={t("LABELS.POSTAL_CODE")}
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                required
              />
              <Input
                type="text"
                placeholder={t("LABELS.CITY")}
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
              />
            </FormFlex>
            <FormFlex>
              <Input
                type="text"
                placeholder={t("LABELS.STREET")}
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                required
              />
              <SmallInput
                type="text"
                placeholder={t("LABELS.STREET_NUMBER")}
                value={streetNumber}
                onChange={(e) => setStreetNumber(e.target.value)}
                required
              />
            </FormFlex>

            <FormFlex>
              <Input
                type="text"
                placeholder={`${t("LABELS.SECONDARY_STREET")}${t(
                  "LABELS.OPTIONAL"
                )}`}
                value={secondaryStreet}
                onChange={(e) => setSecondaryStreet(e.target.value)}
              />
              <Input
                type="text"
                placeholder={`${t("LABELS.BUILDING_NUMBER")}${t(
                  "LABELS.OPTIONAL"
                )}`}
                value={buildingNumber}
                onChange={(e) => setBuildingNumber(e.target.value)}
              />
            </FormFlex>
            <FormFlex>
              <PasswordTitle>
                {t("ADDRESS_BOOK_FORM.DELIVERY_TO")}
              </PasswordTitle>
              <AddressType
                $isActive={type === "Home"}
                onClick={() => setType("Home")}
              >
                {t("ADDRESS_BOOK_FORM.HOME")}
              </AddressType>
              <AddressType
                $isActive={type === "Work"}
                onClick={() => setType("Work")}
              >
                {t("ADDRESS_BOOK_FORM.WORK")}
              </AddressType>
              <AddressType
                $isActive={type === "Other"}
                onClick={() => setType("Other")}
              >
                {t("ADDRESS_BOOK_FORM.OTHER")}
              </AddressType>
            </FormFlex>
          </FormPart>

          <FormFlex>
            <Button type="submit">{t("BUTTONS.SAVE_AND_DELIVER_HERE")}</Button>
            <CancelButton type="button" onClick={handleCancelClick}>
              {t("BUTTONS.CANCEL")}
            </CancelButton>
          </FormFlex>
        </Form>
      </LeftDiv>
    </Container>
  );
};

export default AddressBookForm;
