import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import PrimaryHeader from "../layouts/header/PrimaryHeader";
import FooterMain from "../layouts/footer/FooterMain";
import ProductMainMain from "../layouts/main/ProductMain";
import HorizontalSwipeSlider from "../components/product/HorizontalSwipeSlider";
import { ProductContext } from "../context/ProductContext";
const Container = styled.div`
  background-color: var(--background-color);
  color: var(--text-color);
`;
const ProductMain = () => {
  const { t, i18n } = useTranslation();
  const { recommendedProducts, product, currencyTag } =
    useContext(ProductContext);
  const [seo, setSeo] = useState({
    title: "Loading...",
    description: "Loading product information...",
    keywords: "",
    url: window.location.href,
    images: [],
  });

  useEffect(() => {
    if (product && product.seo_data) {
      const seoData = product.seo_data; // Uzimamo SEO podatke direktno iz product objekta
      const seoTitle =
        seoData.title || `${product.category_name} ${product.name} | SnusCo`;
      const seoDescription = seoData.description || product.short_description;
      const seoKeywords =
        seoData.keywords ||
        `${product.category_name} ${product.name},  ${product.nicotine}mg, ${product.flavor}, ${product.category_name}, ${product.name}`;
      const productUrl = `https://www.snusco.com/${
        i18n.language
      }/${product.category_name.toLowerCase().replace(/ /g, "-")}-${product.name
        .toLowerCase()
        .replace(/ /g, "-")}`;

      // Pronalazimo primarnu sliku iz SEO podataka ili koristimo slike iz proizvoda
      const primaryImage =
        seoData.og_image ||
        product.images.find((image) => image.is_primary)?.large;
      const additionalImages = product.images
        .filter((image) => !image.is_primary)
        .map((image) => image.large);

      setSeo({
        title: seoTitle,
        description: seoDescription,
        keywords: seoKeywords,
        url: productUrl,
        images: [primaryImage, ...additionalImages],
      });
    }
  }, [product, i18n.language]);
  return (
    <Container>
      <Helmet>
        <html lang={i18n.language} />
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:type" content="product" />
        <meta property="og:url" content={seo.url} />

        {/* Open Graph slike - Primarna slika na prvom mestu */}
        {seo.images.map((image, index) => (
          <meta key={index} property="og:image" content={image} />
        ))}

        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="SnusCo" />
        <link rel="canonical" href={seo.url} />
        {/* JSON-LD (Structured Data) */}
        {product && (
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "Product",
              "name": "${seo.title}",
              "description": "${seo.description}",
              "sku": "${product?.sku}",
              "brand": {
                "@type": "Brand",
                "name": "${product?.category_name}"
              },
              "offers": {
                "@type": "Offer",
                "priceCurrency": "${currencyTag}",
                "price": "${product?.price}",
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock",
                "url": "${seo.url}"
              },
              "image": "${seo.images[0]}"
            }
          `}
          </script>
        )}
      </Helmet>
      <PrimaryHeader />
      <ProductMainMain />
      <HorizontalSwipeSlider items={recommendedProducts} />
      <FooterMain />
    </Container>
  );
};

export default ProductMain;
