import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import APIService from "../../services/APIService";
import { AuthUserContext } from "../../context/AuthUserContext";

import CreditCard from "./CreditCard";
import { ProductContext } from "../../context/ProductContext";
import { useSelector } from "react-redux";
import {
  DOMAIN,
  freeShippingThreshold,
  getShippingCostPrice,
} from "../../utils/global_const";
import Spinner from "../animations/Spinner";
import CreditCardPaymentForm from "./CreditCardPaymentForm";

const CreditCardsCheckout = ({ address, setClientSecret }) => {
  const [cards, setCards] = useState([]);
  const { userProfile, authTokens, selectedCard, handleSelectCard } =
    useContext(AuthUserContext);
  const [isLoading, setIsLoading] = useState(true);

  const { shippingMethod } = useContext(ProductContext);
  const [shippingCost, setShippingCost] = useState(0.0);
  const cartItems = useSelector((state) => state.cart.itemsList);

  // Calculate subtotal and grand total
  const subtotal = cartItems.reduce(
    (total, item) => total + item.product.discount_price * item.quantity,
    0
  );
  useEffect(() => {
    if (freeShippingThreshold > subtotal) {
      setShippingCost(shippingMethod.price);
    } else {
      setShippingCost(0);
    }
  }, [subtotal]);

  const grandTotal = Math.round((subtotal + shippingCost) * 100);

  useEffect(() => {
    const billingDetails = {
      name: `${userProfile.first_name} ${userProfile.last_name}`,
      address: address.street,
      city: address.city,
      postal_code: address.postal_code,
      country: address.country,
    };

    APIService.CreatePaymentIntent(
      {
        amount: grandTotal, // amount in cents
        email: userProfile.email,
        billing_details: billingDetails,
        description: `Purchase for order ${DOMAIN}`, // Add relevant description
        phone_number: address.phone_number,
      },
      authTokens.access
    )
      .then((data) => {
        setClientSecret(data.clientSecret);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching client secret:", error);
      });
  }, [grandTotal, address, authTokens]);

  useEffect(() => {
    const fetchSavedCards = async () => {
      try {
        const data = await APIService.getSavedPaymentMethods(authTokens.access);
        setCards(data);
        if (data.length > 0) {
          handleSelectCard(data[0].id); // Automatski postavi prvu karticu
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchSavedCards();
  }, [authTokens]);

  if (isLoading) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      <TopDiv>
        <Header>Secure payment</Header>
        {cards.length > 0 && (
          <Button
            onClick={() => {
              if (selectedCard) {
                handleSelectCard(null);
              } else {
                handleSelectCard(cards[0].id);
              }
            }}
          >
            {selectedCard ? "New Card" : "Cards"}
          </Button>
        )}
      </TopDiv>

      {cards.length === 0 ? (
        <CreditCardPaymentForm />
      ) : (
        <>
          {selectedCard ? (
            <CardList>
              {cards.map((card) => (
                <CreditCard card={card} setCards={setCards} key={card.id} />
              ))}
            </CardList>
          ) : (
            <CreditCardPaymentForm />
          )}
        </>
      )}
      <ButtonDiv></ButtonDiv>
    </Container>
  );
};

export default CreditCardsCheckout;

// Styled Components
const Container = styled.div`
  width: 100%;
`;
const TopDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-color-hover);
`;
const Header = styled.h2`
  padding: var(--spacing-md) 2%;
  text-transform: uppercase;
  margin-bottom: 8px;
  width: 96%;
`;

const CardList = styled.div`
  margin-top: 10px;

  @media (min-width: 1025px) {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    max-width: 810px;
    gap: 10px;
  }
`;
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1025px) {
    display: block;
  }
  margin-bottom: 20px;
`;
const Button = styled.button`
  min-width: 140px;
  margin-right: 2%;
`;
