import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import MainHeader from "../layouts/header/PrimaryHeader";
import Footer from "../layouts/footer/FooterMain";
import ShopMain from "../layouts/main/product/ShopMain";
import PRODUCT_CHUNK, { DEFAULT_LANGUAGE } from "../utils/global_const";
import { useParams } from "react-router-dom";
import { ProductContext } from "../context/ProductContext";

const SEOConfig = {
  en: {
    title: "Shop | SnusCo",
    description:
      "Explore our wide range of nicotine pouches and find your perfect match at SnusCo. Shop now and enjoy premium quality and variety.",
    keywords:
      "nicotine pouches, snus, buy snus online, best nicotine pouches, SnusCo shop",
    url: "https://www.snusco.com/en/nicotine-pouches",
    images: ["https://www.snusco.com/assets/default-category-image.jpg"],
  },
  de: {
    title: "Shop | SnusCo",
    description:
      "Entdecken Sie unsere große Auswahl an Nikotinbeuteln und finden Sie Ihren perfekten Match bei SnusCo. Jetzt einkaufen und Premiumqualität und Vielfalt genießen.",
    keywords:
      "Nikotinbeutel, snus, snus online kaufen, beste Nikotinbeutel, SnusCo Shop",
    url: "https://www.snusco.com/de/nicotine-pouches",
    images: ["https://www.snusco.com/assets/default-category-image.jpg"],
  },
  it: {
    title: "Negozio | SnusCo",
    description:
      "Esplora la nostra vasta gamma di pouches di nicotina e trova il tuo abbinamento perfetto su SnusCo. Acquista ora e goditi la qualità e la varietà premium.",
    keywords:
      "pouches di nicotina, snus, acquista snus online, migliori pouches di nicotina, SnusCo shop",
    url: "https://www.snusco.com/it/nicotine-pouches",
    images: ["https://www.snusco.com/assets/default-category-image.jpg"],
  },
  fr: {
    title: "Boutique | SnusCo",
    description:
      "Explorez notre large gamme de sachets de nicotine et trouvez votre combinaison parfaite chez SnusCo. Achetez maintenant et profitez de la qualité et de la variété premium.",
    keywords:
      "sachets de nicotine, snus, acheter snus en ligne, meilleurs sachets de nicotine, SnusCo shop",
    url: "https://www.snusco.com/fr/nicotine-pouches",
    images: ["https://www.snusco.com/assets/default-category-image.jpg"],
  },
  hu: {
    title: "Üzlet | SnusCo",
    description:
      "Fedezze fel a nikotinos tasakok széles választékát, és találja meg az Önnek megfelelő párosítást a SnusCo-nál. Vásároljon most, és élvezze a prémium minőséget és változatosságot.",
    keywords:
      "nikotinos tasakok, snus, snus online vásárlás, legjobb nikotinos tasakok, SnusCo shop",
    url: "https://www.snusco.com/hu/nicotine-pouches",

    images: ["https://www.snusco.com/assets/default-category-image.jpg"],
  },
  tr: {
    title: "Mağaza | SnusCo",
    description:
      "SnusCo'da geniş nikotin torbası yelpazemizi keşfedin ve size en uygun olanı bulun. Şimdi alışveriş yapın ve premium kalite ve çeşitliliğin tadını çıkarın.",
    keywords:
      "nikotin torbaları, snus, snus online satın al, en iyi nikotin torbaları, SnusCo shop",
    url: "https://www.snusco.com/tr/nicotine-pouches",
    images: ["https://www.snusco.com/assets/default-category-image.jpg"],
  },
  sr: {
    title: "Prodavnica | SnusCo",
    description:
      "Istražite našu široku ponudu nikotinskih kesica i pronađite svoj savršeni izbor na SnusCo. Kupujte sada i uživajte u premium kvalitetu i raznovrsnosti.",
    keywords:
      "nikotinske kesice, snus, kupi snus online, najbolje nikotinske kesice, SnusCo shop",
    url: "https://www.snusco.com/sr/nicotine-pouches",
    images: ["https://www.snusco.com/assets/default-category-image.jpg"],
  },
};

const Shop = () => {
  const { t, i18n } = useTranslation();
  const { loadProducts, loadProductsByCategorySlug, setCategory, category } =
    useContext(ProductContext);

  const { slug } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!slug) {
      loadProducts();
      setCategory(null);
      return;
    }
    loadProductsByCategorySlug(slug);
  }, [slug]);

  const [seo, setSeo] = useState(SEOConfig[DEFAULT_LANGUAGE]);

  useEffect(() => {
    if (category) {
      const seoTitle = `${category.name} | SnusCo`;
      const seoDescription = category.short_description;
      const defaultKeywords =
        SEOConfig[i18n.language]?.keywords ||
        SEOConfig[DEFAULT_LANGUAGE].keywords;
      const seoKeywords = `${category.name}, ${defaultKeywords}`;
      const categoryUrl = `https://www.snusco.com/${
        i18n.language
      }/nicotine-pouches/${category.name.toLowerCase().replace(/ /g, "-")}`;

      // Pronalazimo primarnu sliku za kategoriju, ako postoji
      const primaryImage =
        category.images?.desktop ||
        "https://www.snusco.com/assets/default-category-image.jpg";

      setSeo({
        title: seoTitle,
        description: seoDescription,
        keywords: seoKeywords,
        url: categoryUrl,
        images: [primaryImage],
      });
    } else {
      setSeo(SEOConfig[i18n.language] || SEOConfig[DEFAULT_LANGUAGE]);
    }
  }, [category, i18n.language]);

  return (
    <div>
      <Helmet>
        <html lang={i18n.language} />
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />

        {/* Open Graph slike - Primarna slika na prvom mestu */}
        {seo.images?.map((image, index) => (
          <meta key={index} property="og:image" content={image} />
        ))}

        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="SnusCo" />
        <link rel="canonical" href={seo.url} />
      </Helmet>
      <MainHeader />
      <ShopMain />
      <Footer />
    </div>
  );
};

export default Shop;
