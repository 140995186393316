import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Home from "./pages/Home";

import LoginMain from "./pages/LoginMain";
import { useContext, useEffect, useState } from "react";
import { AuthUserProvider } from "./context/AuthUserContext";
import LottieConffete from "./components/animations/LottieConffete";
import Conffete from "./components/popups/Conffete";
import { ProductContext } from "./context/ProductContext";
import Product from "./pages/Product";
import Account from "./pages/account/Account";
import InviteFriend from "./pages/account/InviteFriend";
import AccountPoints from "./pages/account/AccountPoints";
import MyBalance from "./pages/account/MyBalance";
import Orders from "./pages/account/Orders";
import Settings from "./pages/account/Settings";
import Voucher from "./pages/account/Voucher";
import AddressBook from "./pages/account/AddressBook";
import RegisterMain from "./pages/RegisterMain";
import ResetPassword from "./pages/ResetPassword";
import SimpleContext from "./context/SimpleContext";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Uvoz stilova
import Shop from "./pages/Shop";
import ShopVariants from "./pages/product/ShopVariants";
import Search from "./pages/Search";
import Checkout from "./pages/Checkout";
import ChangePreferences from "./pages/account/ChangePreferences";

import Chat from "./chat/Chat";
import { ChatProvider } from "./context/ChatContext";
import Cart from "./pages/Cart";
import i18next from "i18next";
import ProductMain from "./pages/ProductMain";
import Profile from "./pages/profile/Profile";
import Blogs from "./pages/Blogs";
import Blog from "./pages/Blog";
import Support from "./pages/Support";
import Error404 from "./pages/Error404";

import PrivacyPolicy from "./pages/PrivacyPolicy";
import ThankYou from "./pages/ThankYou";
import Verification from "./pages/Verification";

function App() {
  // Kombinujemo temu i klasu root elementa u jedan objekat stanja
  const {
    activateConffete,
    loadProducts,
    loadCategories,
    categories,
    category,
    products,
  } = useContext(ProductContext);
  const { showLogin, showRegister, showResetPassword, showVerification } =
    useContext(SimpleContext);
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") || "light-theme"
  );

  useEffect(() => {
    // Primena izabrane teme na element document
    document.documentElement.className = theme;
    // Čuvanje izabrane teme u localStorage
    localStorage.setItem("theme", theme);
  }, []);

  useEffect(() => {
    // if (!category && !products.length) {
    //   loadProducts();
    //   console.log("druga dva");
    // }
    if (!categories || !categories.length) {
      loadCategories();
    }
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <AuthUserProvider>
          <ChatProvider>
            {/* {activateConffete && <LottieConffete />} */}
            {activateConffete && <Conffete />}
            <ToastContainer style={{ zIndex: "999999" }} />
            {showLogin && <LoginMain />}
            {showRegister && <RegisterMain />}
            {showResetPassword && <ResetPassword />}
            {showVerification && <Verification />}
            {/* <Chat /> */}

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/:lang" element={<Home />} />
              {/* ACCOUNT */}
              {/* <Route path="/:lang/account" element={<Account />} />
              <Route path="/:lang/invite-friend" element={<InviteFriend />} />
              <Route path="/:lang/account-points" element={<AccountPoints />} />
              <Route path="/:lang/my-balance" element={<MyBalance />} />
              <Route path="/:lang/orders" element={<Orders />} />
              <Route path="/:lang/voucher" element={<Voucher />} />
              <Route path="/:lang/settings" element={<Settings />} /> 
              <Route
                path="/:lang/user-preferences"
                element={<ChangePreferences />}
              ></Route>
              <Route
                path="/:lang/address-book/:addressId"
                element={<AddressBook />}
              />
              */}
              <Route path="/:lang/profile" element={<Profile />} />
              <Route
                path="/:lang/nicotine-pouches/:slug?"
                element={<Shop />}
              ></Route>
              <Route path="/:lang/search" element={<Search />}></Route>
              <Route
                path="/:lang/new-arrivals"
                element={<ShopVariants variant="new-arrivals" />}
              ></Route>
              <Route
                path="/:lang/best-sellers"
                element={<ShopVariants variant="best-sellers" />}
              ></Route>
              <Route path="/:lang/:slug" element={<ProductMain />} />
              <Route path="/:lang/cart" element={<Cart />} />
              <Route path="/:lang/checkout" element={<Checkout />} />
              <Route
                path="/:lang/reset-password/:uid/:token/"
                element={<Home />}
              />
              <Route path="/:lang/blogs" element={<Blogs />} />
              <Route path="/:lang/blog/:slug" element={<Blog />} />
              <Route path="/:lang/support" element={<Support />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />

              <Route path="/:lang/thank-you" element={<ThankYou />} />
              <Route path="*" element={<Error404 />}></Route>
            </Routes>
          </ChatProvider>
        </AuthUserProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
