import { useContext } from "react";
import styled from "styled-components";
import { AuthUserContext } from "../../context/AuthUserContext";
import { ReactComponent as Silver } from "../../assets/images/snusco_silver.svg";
import { ReactComponent as Gold } from "../../assets/images/snusco_gold.svg";
import { ReactComponent as Diamond } from "../../assets/images/snusco_diamond.svg";
import { useTranslation } from "react-i18next";

const CoinsContainer = styled.div`
  width: 100%;
  background-color: var(--background-color);
`;
const CoinsWrapper = styled.div`
  padding: var(--spacing-xs) var(--spacing-md);
  margin: var(--spacing-xxs) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 768px) {
    padding: var(--spacing-xl);
  }
  border-bottom: 1px solid #d7e0e7;
`;
const CoinsTitle = styled.h3`
  font-size: var(--font-size-base);
  font-weight: normal;
  text-transform: uppercase;
`;
const CoinsInfo = styled.p`
  font-size: var(--font-size-small);
  font-weight: normal;
  color: var(--p-color);
`;
const FlexDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-md);
  cursor: pointer;
  width: 60%;
`;
const CoinFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xxs);
`;

const CardsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing-md);
  margin: var(--spacing-lg) 0;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;
const CardContainer = styled.div`
  font-family: "Larken-Light";
  opacity: ${(props) => (props.$active ? "1" : "0.5")};
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: var(--spacing-xl) 0;
  position: relative;
  overflow: hidden;
  width: 94%;
  @media (min-width: 768px) {
    width: 100%;
  }
`;
const BrT = styled.div`
  height: 1px;
  background-color: var(--text-color);
  width: 80%;
  margin: 0 0 var(--spacing-lg) 0;
  opacity: 0.1;
  position: relative;
`;
const LockSvg = styled.svg`
  position: absolute;
  left: calc(50% - 11px - 1px);
  top: -10px;
  border: 1px solid var(--text-color);
  border-radius: 50%;
  padding: 2px;
  background-color: var(--background-color);
`;
const Subtitle = styled.h5`
  font-weight: 600;
  margin-bottom: var(--spacing-sm);
  font-family: "Satoshi";
`;
const Text = styled.div`
  font-family: "Satoshi";
  font-size: var(--font-size-small);
  color: var(--p-color);

  text-align: center;
`;
const ActiveFlag = styled.div`
  background-color: var(--primary-color);
  color: var(--background-color);
  position: absolute;
  right: -30px;
  top: 20px;
  text-align: center;
  width: 140px;
  transform: rotate(45deg);
`;

const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl) 0;
`;
const BottomTitle = styled.h2`
  font-size: var(--font-size-xxlarge);
  text-align: center;
`;
const BottomWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 85%;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
const StepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: var(--spacing-md) 0;
`;
const BottomStep = styled.div`
  border-radius: 50%;
  background-color: rgba(86, 147, 191, 0.09);
  padding: var(--spacing-md) var(--spacing-sm);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-sm);
  width: 50px;
  height: 40px;
`;
const BottomStepTitle = styled.div`
  font-weight: bold;
  font-size: var(--font-size-base);
  color: var(--primary-color);
`;
const BrMiddle = styled.div`
  height: 40px;
  border-bottom: 1px solid #c3d2db;
  width: 100%;

  opacity: 1;
  height: 30px;
  width: 50%;
  border-right: 1px solid #c3d2db;
  border-bottom: none;
  @media (min-width: 768px) {
    border-bottom: 1px solid #c3d2db;
    width: 100%;
    border-right: none;
    height: 50px;
  }
`;
const BottomText = styled(Subtitle)`
  margin: 0;
  color: var(--p-color);
  text-align: center;
  width: 90%;
  font-family: "Satoshi";

  @media (min-width: 768px) {
    width: auto;
  }
`;

const Coins = () => {
  const { t } = useTranslation();
  const { userProfile } = useContext(AuthUserContext);

  return (
    <div>
      <CoinsContainer>
        <CoinsWrapper>
          <FlexDiv>
            <div>
              <CoinsTitle>{t("COINS.TITLE")}</CoinsTitle>
              <CoinsInfo>{t("COINS.INFO")}</CoinsInfo>
            </div>
          </FlexDiv>
          <CoinFlex>
            <svg
              width="27"
              height="28"
              viewBox="0 0 27 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3594 27.3594C20.7376 27.3594 26.7188 21.3782 26.7188 14C26.7188 6.62182 20.7376 0.640625 13.3594 0.640625C5.9812 0.640625 0 6.62182 0 14C0 21.3782 5.9812 27.3594 13.3594 27.3594Z"
                fill="#FEDA2C"
              />
              <path
                d="M13.3595 24.298C19.0471 24.298 23.6579 19.6873 23.6579 13.9996C23.6579 8.31194 19.0471 3.70117 13.3595 3.70117C7.6718 3.70117 3.06104 8.31194 3.06104 13.9996C3.06104 19.6873 7.6718 24.298 13.3595 24.298Z"
                fill="#FCAA17"
              />
              <path
                d="M13.3595 6.08545L15.7016 10.8311L20.9385 11.5921L17.1491 15.2861L18.0437 20.5019L13.3595 18.0393L8.67527 20.5019L9.56988 15.2861L5.78027 11.5921L11.0174 10.8311L13.3595 6.08545Z"
                fill="#FEDA2C"
              />
            </svg>
            {userProfile?.user_points.points}
          </CoinFlex>
        </CoinsWrapper>
      </CoinsContainer>
      <CardsContainer>
        <CardContainer $active={true}>
          {userProfile?.user_points.level === 1 && (
            <ActiveFlag>{t("COINS.ACTIVE")}</ActiveFlag>
          )}
          <CoinsTitle>{t("COINS.MEMBERSHIP.SILVER.TITLE")}</CoinsTitle>
          <Silver />
          <BrT />
          <Subtitle>{t("COINS.MEMBERSHIP.SILVER.POINTS")}</Subtitle>
          <Text>{t("COINS.MEMBERSHIP.SILVER.MULTIPLIER")} </Text>
          <Text>{t("COINS.MEMBERSHIP.SILVER.REFERRAL_REWARD")}</Text>
        </CardContainer>
        <CardContainer $active={userProfile?.level >= 2 ? true : false}>
          {userProfile?.user_points.level === 2 && (
            <ActiveFlag>{t("COINS.ACTIVE")}</ActiveFlag>
          )}
          <CoinsTitle>{t("COINS.MEMBERSHIP.GOLD.TITLE")}</CoinsTitle>
          <Gold />
          <BrT>
            {userProfile?.user_points.level < 2 && (
              <LockSvg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_503_10468)">
                  <path
                    d="M11.4184 2.38525C12.628 2.38525 13.7881 2.86577 14.6434 3.72109C15.4987 4.57641 15.9792 5.73647 15.9792 6.94608V9.68258C16.705 9.68258 17.401 9.97088 17.9142 10.4841C18.4274 10.9973 18.7157 11.6933 18.7157 12.4191V17.8921C18.7157 18.6178 18.4274 19.3139 17.9142 19.8271C17.401 20.3402 16.705 20.6286 15.9792 20.6286H6.85759C6.13183 20.6286 5.43579 20.3402 4.92259 19.8271C4.4094 19.3139 4.12109 18.6178 4.12109 17.8921V12.4191C4.12109 11.6933 4.4094 10.9973 4.92259 10.4841C5.43579 9.97088 6.13183 9.68258 6.85759 9.68258V6.94608C6.85759 5.73647 7.3381 4.57641 8.19342 3.72109C9.04875 2.86577 10.2088 2.38525 11.4184 2.38525ZM11.4184 13.3312C10.9582 13.3311 10.5149 13.5049 10.1774 13.8179C9.83989 14.1308 9.63316 14.5598 9.59865 15.0187L9.59408 15.1556C9.59408 15.5164 9.70108 15.8691 9.90154 16.1691C10.102 16.4691 10.3869 16.7029 10.7203 16.841C11.0536 16.9791 11.4204 17.0152 11.7743 16.9448C12.1282 16.8745 12.4533 16.7007 12.7084 16.4456C12.9635 16.1904 13.1373 15.8654 13.2077 15.5115C13.2781 15.1576 13.242 14.7908 13.1039 14.4574C12.9658 14.1241 12.732 13.8392 12.432 13.6387C12.1319 13.4382 11.7792 13.3312 11.4184 13.3312ZM11.4184 4.20958C10.6927 4.20958 9.99661 4.49789 9.48342 5.01109C8.97023 5.52428 8.68192 6.22032 8.68192 6.94608V9.68258H14.1549V6.94608C14.1549 6.22032 13.8666 5.52428 13.3534 5.01109C12.8402 4.49789 12.1442 4.20958 11.4184 4.20958Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_503_10468">
                    <rect
                      width="21.892"
                      height="21.892"
                      fill="white"
                      transform="translate(0.472656 0.561035)"
                    />
                  </clipPath>
                </defs>
              </LockSvg>
            )}
          </BrT>
          <Subtitle>{t("COINS.MEMBERSHIP.GOLD.POINTS")}</Subtitle>
          <Text>{t("COINS.MEMBERSHIP.GOLD.MULTIPLIER")}</Text>
          <Text>{t("COINS.MEMBERSHIP.GOLD.REFERRAL_REWARD")}</Text>
          <Text>{t("COINS.MEMBERSHIP.GOLD.ENTRY_POINTS")}</Text>
        </CardContainer>
        <CardContainer
          $active={userProfile?.user_points.level >= 3 ? true : false}
        >
          {userProfile?.user_points.level === 3 && (
            <ActiveFlag>{t("COINS.ACTIVE")}</ActiveFlag>
          )}
          <CoinsTitle>{t("COINS.MEMBERSHIP.DIAMOND.TITLE")}</CoinsTitle>
          <Diamond />
          <BrT>
            {userProfile?.user_points.level < 3 && (
              <LockSvg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_503_10468)">
                  <path
                    d="M11.4184 2.38525C12.628 2.38525 13.7881 2.86577 14.6434 3.72109C15.4987 4.57641 15.9792 5.73647 15.9792 6.94608V9.68258C16.705 9.68258 17.401 9.97088 17.9142 10.4841C18.4274 10.9973 18.7157 11.6933 18.7157 12.4191V17.8921C18.7157 18.6178 18.4274 19.3139 17.9142 19.8271C17.401 20.3402 16.705 20.6286 15.9792 20.6286H6.85759C6.13183 20.6286 5.43579 20.3402 4.92259 19.8271C4.4094 19.3139 4.12109 18.6178 4.12109 17.8921V12.4191C4.12109 11.6933 4.4094 10.9973 4.92259 10.4841C5.43579 9.97088 6.13183 9.68258 6.85759 9.68258V6.94608C6.85759 5.73647 7.3381 4.57641 8.19342 3.72109C9.04875 2.86577 10.2088 2.38525 11.4184 2.38525ZM11.4184 13.3312C10.9582 13.3311 10.5149 13.5049 10.1774 13.8179C9.83989 14.1308 9.63316 14.5598 9.59865 15.0187L9.59408 15.1556C9.59408 15.5164 9.70108 15.8691 9.90154 16.1691C10.102 16.4691 10.3869 16.7029 10.7203 16.841C11.0536 16.9791 11.4204 17.0152 11.7743 16.9448C12.1282 16.8745 12.4533 16.7007 12.7084 16.4456C12.9635 16.1904 13.1373 15.8654 13.2077 15.5115C13.2781 15.1576 13.242 14.7908 13.1039 14.4574C12.9658 14.1241 12.732 13.8392 12.432 13.6387C12.1319 13.4382 11.7792 13.3312 11.4184 13.3312ZM11.4184 4.20958C10.6927 4.20958 9.99661 4.49789 9.48342 5.01109C8.97023 5.52428 8.68192 6.22032 8.68192 6.94608V9.68258H14.1549V6.94608C14.1549 6.22032 13.8666 5.52428 13.3534 5.01109C12.8402 4.49789 12.1442 4.20958 11.4184 4.20958Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_503_10468">
                    <rect
                      width="21.892"
                      height="21.892"
                      fill="white"
                      transform="translate(0.472656 0.561035)"
                    />
                  </clipPath>
                </defs>
              </LockSvg>
            )}
          </BrT>
          <Subtitle>{t("COINS.MEMBERSHIP.DIAMOND.POINTS")}</Subtitle>
          <Text>{t("COINS.MEMBERSHIP.DIAMOND.MULTIPLIER")}</Text>
          <Text>{t("COINS.MEMBERSHIP.DIAMOND.REFERRAL_REWARD")}</Text>
          <Text>{t("COINS.MEMBERSHIP.DIAMOND.ENTRY_POINTS")}</Text>
        </CardContainer>
      </CardsContainer>
      <BottomTitle>{t("COINS.HOW_IT_WORKS")}</BottomTitle>
      <BottomContainer>
        <BottomWrapper>
          <StepContainer>
            <BottomStep>
              <div style={{ textAlign: "center" }}>
                <BottomStepTitle>100+</BottomStepTitle>
                <Subtitle style={{ margin: "0" }}>Points</Subtitle>
              </div>
            </BottomStep>
            <Subtitle>{t("COINS.STEPS.INVITE.TITLE")}</Subtitle>
            <BottomText>{t("COINS.STEPS.INVITE.TEXT")}</BottomText>
          </StepContainer>
          <BrMiddle />
          <StepContainer>
            <BottomStep>
              <div style={{ textAlign: "center" }}>
                <BottomStepTitle>100+</BottomStepTitle>
                <Subtitle style={{ margin: "0" }}>
                  {t("COINS.EARNED_POINTS")}
                </Subtitle>
              </div>
            </BottomStep>
            <Subtitle>{t("COINS.STEPS.SHOP.TITLE")}</Subtitle>
            <BottomText>{t("COINS.STEPS.SHOP.TEXT")}</BottomText>
          </StepContainer>
          <BrMiddle />
          <StepContainer>
            <BottomStep>
              <div style={{ textAlign: "center" }}>
                <BottomStepTitle>100+</BottomStepTitle>
                <Subtitle style={{ margin: "0" }}>
                  {t("COINS.EARNED_POINTS")}
                </Subtitle>
              </div>
            </BottomStep>
            <Subtitle>{t("COINS.STEPS.NEWSLETTER.TITLE")}</Subtitle>
            <BottomText>{t("COINS.STEPS.NEWSLETTER.TEXT")}</BottomText>
          </StepContainer>
        </BottomWrapper>
      </BottomContainer>
    </div>
  );
};

export default Coins;
