import styled from "styled-components";
import { useContext, useEffect } from "react";
import { ProductContext } from "../../context/ProductContext";
import { useTranslation } from "react-i18next";
import bgImg from "../../assets/images/home/bg.webp";
import bannerMobileImage from "../../assets/images/home-mobile/home-mobile_2x.webp";
import smallImg from "../../assets/images/home/lyft.webp";

const Container = styled.div`
  overflow: hidden;
  background-image: url(${(props) => props.$mobileImage});
  background-size: cover;
  background-position: center;
  text-align: center;
  @media (min-width: 768px) {
    background-image: url(${(props) => props.$desktopImage});
    text-align: center;
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column-reverse;
  @media (min-width: 768px) {
    width: var(--max-width-container);
    flex-direction: row;
  }
`;

const InnerHeroContainer = styled.div`
  z-index: 9;

  @media (min-width: 768px) and (max-width: 1040px) {
  }
  @media (min-width: 768px) {
    height: auto;
  }
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 12px;
  height: 90%;
`;

const HeroTitle = styled.h1`
  color: var(--text-color);
  //font-family: Larken;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 800px;

  @media (min-width: 768px) {
    text-align: left;
  }
`;
const HeroText = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`;
const HeroButton = styled.button``;

const SmallImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px; /* Podesi veličinu kontejnera po potrebi */
  height: 320px;
  background-color: transparent; /* Svetlo siva pozadina za kontrast */
  overflow: hidden; /* Sprečava da slika "izađe" iz kontejnera */
  @media (min-width: 768px) {
    width: 420px; /* Podesi veličinu kontejnera po potrebi */
    height: 420px;
  }
`;

const SmallImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Slika će se proporcionalno prilagoditi i popuniti kontejner */
  border-radius: 50%; /* Osigurava da i slika prati zaobljene ivice kontejnera */
`;

const HeroCategory = () => {
  const { t } = useTranslation();
  const { category } = useContext(ProductContext);
  const handleScroll = () => {
    const viewportHeight = window.innerHeight;
    let scrollAmount = viewportHeight;

    if (window.matchMedia("(min-width: 1024px)").matches) {
      // Desktop and larger tablets
      scrollAmount = viewportHeight * 0.85; // Scroll 75% of viewport height
    } else if (window.matchMedia("(min-width: 768px)").matches) {
      // Smaller tablets
      scrollAmount = viewportHeight * 1; // Scroll 85% of viewport height
    }

    window.scrollBy({
      top: scrollAmount,
      left: 0,
      behavior: "smooth",
    });
  };
  // useEffect(() => {
  //   const button = document.querySelector(".button-transparent");

  //   if (button) {
  //     button.addEventListener("click", handleScroll);
  //   }

  //   return () => {
  //     if (button) {
  //       button.removeEventListener("click", handleScroll);
  //     }
  //   };
  // }, []);
  return (
    <Container
      className="hero-image"
      //$mobileImage={category.images?.mobile}
      //$desktopImage={category.images?.desktop}
      $desktopImage={bgImg}
      $mobileImage={bgImg}
    >
      <Wrapper>
        <InnerHeroContainer>
          <TextContainer>
            <HeroTitle>{category.name}</HeroTitle>
            <HeroText>{category.seo_data?.short_description}</HeroText>
          </TextContainer>
          <HeroButton className="button-transparent" onClick={handleScroll}>
            {t("BUTTONS.EXPLORE")}
          </HeroButton>
        </InnerHeroContainer>
        <SmallImageContainer>
          <SmallImage
            src={`https://snus-s3.s3.eu-north-1.amazonaws.com/categories/desktop/${category.slug}.webp`}
            alt="Image"
          />
        </SmallImageContainer>
      </Wrapper>
    </Container>
  );
};

export default HeroCategory;
