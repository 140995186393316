import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigation } from "../../utils/navigation";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../context/AuthUserContext";
import { useTranslation } from "react-i18next";
import { languages } from "../../utils/global_const";
import { useMediaQuery } from "react-responsive";
const ItemContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 90%;
  overflow: hidden;
  gap: 8px;
  text-transform: uppercase;
  font-size: var(--font-size-small);
  font-weight: bold;
  font-weight: 500;
  justify-content: space-between;
  padding: var(--spacing-md) 0;
  @media (min-width: 768px) {
    width: auto;
    justify-content: auto;
  }
`;
const Span = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  font-size: var(--font-size-small);
`;
const Picture = styled.picture`
  width: 30px;
  //height: auto;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Image = styled.img`
  width: 100%;
  height: auto; // Da bi se zadržale proporcije slike
  object-fit: cover;
`;
const SVGArrow = styled.svg``;

const RadioContainer = styled.div`
  //top: calc(40px + var(--spacing-md));
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  background-color: var(--background-color-hover);
  @media (min-width: 768px) {
    position: absolute;
    //padding: 12px;
    width: auto;
    top: var(--navbar-height-desktop);
    background-color: var(--background-color);
  }
`;
const StyledLabel = styled.label`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  margin: 8px 0;
  font-size: var(--font-size-small);
  gap: 12px;
  @media (min-width: 768px) {
    font-size: var(--font-size-base);
  }
  &:hover {
    color: var(--primary-color); // Boja teksta kada je labela hoverovana
  }
`;

// Stilizovani input element
const StyledInput = styled.div`
  cursor: pointer;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  border-radius: 20px;
  border: 1px solid var(--text-color-hover);
  opacity: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CheckedRadio = styled.div`
  background-color: var(--primary-color);
  width: 50%;
  height: 50%;
  min-width: 8px;
  min-height: 8px;
  border-radius: 20px;
`;
const FlagTitleRadio = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-sm);
  max-width: 100%;
`;
const ChangePreferencesIcon = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { location, setLocation } = useContext(AuthUserContext);
  //const { goToUserPreferences } = useNavigation();
  const { i18n } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const [currentLang, setCurrentLang] = useState(
    languages.find(
      (lang) => lang.code.toLowerCase() === i18n.language.toLowerCase()
    ) || languages[0]
  );
  const navigate = useNavigate();
  const containerRef = useRef(null);

  useEffect(() => {
    // Ažurirajte currentLang svaki put kada se promeni i18n.language
    const foundLang = languages.find(
      (lang) => lang.code.toLowerCase() === i18n.language.toLowerCase()
    );
    setCurrentLang(foundLang || languages[0]); // Postavite na pronađeni jezik ili na podrazumevani jezik ako pronađeni jezik ne postoji
  }, [i18n.language]);

  // useEffect(() => {
  //   axios
  //     .get("https://ipapi.co/json/")
  //     .then((response) => {
  //       setLocation({
  //         country: response.data.country_name,
  //         countryCode: response.data.country_code.toLowerCase(),
  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching the IP details:", error);
  //     });
  // }, []);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };
  useEffect(() => {
    if (isMobile) {
      return;
    }
    if (showMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang.code);
    setCurrentLang(lang);
    setShowMenu(false);

    const currentUrl = window.location.pathname;
    const urlSegments = currentUrl.split("/").filter((segment) => segment);

    // Izbacujemo prethodni lang kod ako postoji
    const newUrlSegments = urlSegments.filter(
      (segment) =>
        !languages.some(
          (language) => language.code.toLowerCase() === segment.toLowerCase()
        )
    );

    // Dodajemo novi lang kod
    newUrlSegments.unshift(lang.code.toLowerCase());

    // Kreiramo novi URL
    const newUrl = `${window.location.origin}/${newUrlSegments.join("/")}`;

    window.location.href = newUrl;
  };

  return (
    <>
      {currentLang && (
        <>
          <ItemContainer onClick={toggleMenu}>
            <Span>
              <Picture>
                <Image
                  src={currentLang.flag}
                  alt={`${currentLang.code}`}
                  loading="lazy"
                />
              </Picture>

              {currentLang.code}
            </Span>
            <SVGArrow
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={showMenu ? { transform: "rotate(180deg)" } : {}}
            >
              <path
                d="M-0.000325203 0.333496L4.66634 5.66683L9.33301 0.333496L-0.000325203 0.333496Z"
                fill="var(--text-color)"
              />
            </SVGArrow>
          </ItemContainer>
          {showMenu && (
            <RadioContainer ref={containerRef}>
              {languages.map((lang) => (
                <StyledLabel
                  key={lang.code}
                  onClick={() => handleLanguageChange(lang)}
                >
                  <FlagTitleRadio>
                    <Picture>
                      <Image
                        width={30}
                        height={30}
                        src={lang.flag}
                        alt={`${lang.code} flag`}
                      />
                    </Picture>
                    {lang.label}
                  </FlagTitleRadio>
                  {/* <StyledInput value={lang.code}>
                    {currentLang.code === lang.code && <CheckedRadio />}
                  </StyledInput> */}
                </StyledLabel>
              ))}
            </RadioContainer>
          )}
        </>
      )}
    </>
  );
};

export default ChangePreferencesIcon;
