import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import PrimaryHeader from "../layouts/header/PrimaryHeader";
import { useEffect, useState } from "react";
import FooterMain from "../layouts/footer/FooterMain";
import CartMain from "../layouts/main/Cart";
import { DEFAULT_LANGUAGE } from "../utils/global_const";
const Container = styled.div`
  background-color: var(--background-color);
  color: var(--text-color);
`;
const SEOConfig = {
  en: {
    lang: "en",
    title: "Cart | SnusCo",
    description:
      "Review your selected nicotine pouches before checkout. Ensure you have everything you need for the ultimate experience.",
    og: {
      title: "Cart | SnusCo",
      description:
        "Finalize your purchase of premium nicotine pouches. Secure your favorites before they’re gone.",
    },
  },
  de: {
    lang: "de",
    title: "Warenkorb | SnusCo",
    description:
      "Überprüfen Sie Ihre ausgewählten Nikotinbeutel vor dem Checkout. Stellen Sie sicher, dass Sie alles haben, was Sie für das ultimative Erlebnis benötigen.",
    og: {
      title: "Warenkorb | SnusCo",
      description:
        "Schließen Sie Ihren Kauf von Premium-Nikotinbeuteln ab. Sichern Sie sich Ihre Favoriten, bevor sie ausverkauft sind.",
    },
  },
  it: {
    lang: "it",
    title: "Carrello | SnusCo",
    description:
      "Rivedi le tue pouches di nicotina selezionate prima del pagamento. Assicurati di avere tutto il necessario per un'esperienza ottimale.",
    og: {
      title: "Carrello | SnusCo",
      description:
        "Finalizza l'acquisto delle tue pouches di nicotina premium. Assicurati i tuoi preferiti prima che finiscano.",
    },
  },
  fr: {
    lang: "fr",
    title: "Panier | SnusCo",
    description:
      "Vérifiez vos sachets de nicotine sélectionnés avant de passer à la caisse. Assurez-vous d'avoir tout ce dont vous avez besoin pour une expérience optimale.",
    og: {
      title: "Panier | SnusCo",
      description:
        "Finalisez votre achat de sachets de nicotine premium. Assurez-vous vos favoris avant qu'ils ne disparaissent.",
    },
  },
  hu: {
    lang: "hu",
    title: "Kosár | SnusCo",
    description:
      "Tekintse át a kiválasztott nikotinos tasakokat a fizetés előtt. Győződjön meg róla, hogy mindene megvan az optimális élményhez.",
    og: {
      title: "Kosár | SnusCo",
      description:
        "Véglegesítse prémium nikotinos tasakjainak vásárlását. Biztosítsa kedvenceit, mielőtt elfogynak.",
    },
  },
  tr: {
    lang: "tr",
    title: "Sepet | SnusCo",
    description:
      "Ödeme yapmadan önce seçtiğiniz nikotin torbalarını gözden geçirin. En iyi deneyim için ihtiyacınız olan her şeyin elinizde olduğundan emin olun.",
    og: {
      title: "Sepet | SnusCo",
      description:
        "Premium nikotin torbaları satın alımınızı tamamlayın. Favorilerinizi tükenmeden önce güvenceye alın.",
    },
  },
  sr: {
    lang: "sr",
    title: "Korpa | SnusCo",
    description:
      "Pregledajte izabrane nikotinske kesice pre nego što nastavite sa plaćanjem. Osigurajte da imate sve što vam je potrebno za vrhunsko iskustvo.",
    og: {
      title: "Korpa | SnusCo",
      description:
        "Završite kupovinu premium nikotinskih kesica. Osigurajte svoje omiljene pre nego što nestanu.",
    },
  },
};

const Cart = () => {
  const { t, i18n } = useTranslation();
  const [seo, setSeo] = useState(SEOConfig[DEFAULT_LANGUAGE]);

  useEffect(() => {
    setSeo(SEOConfig[i18n.language] || SEOConfig[DEFAULT_LANGUAGE]);
  }, [i18n.language]);
  return (
    <Container>
      <Helmet>
        <html lang={seo.lang} />
        <title>{seo.title}</title>
        <link
          rel="alternate"
          href="http://www.snusco.com/en/cart"
          hreflang={seo.lang}
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/de/cart"
          hreflang="de"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/it/cart"
          hreflang="it"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/fr/cart"
          hreflang="fr"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/hu/cart"
          hreflang="hu"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/tr/cart"
          hreflang="tr"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/sr-latn/cart"
          hreflang="sr-latn"
        />
        <link
          rel="alternate"
          href="http://www.snusco.com/cart"
          hreflang="x-default"
        />

        <meta name="description" content={seo.description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={seo.og.title} />
        <meta property="og:description" content={seo.og.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://www.snusco.com/cart" />
        <meta
          property="og:image"
          content="http://www.snusco.com/assets/og-image.jpg"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="SnusCo" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <PrimaryHeader />
      <CartMain />
      <FooterMain />
    </Container>
  );
};

export default Cart;
